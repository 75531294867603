const {
  NODE_ENV,
  VUE_APP_VIDEO_CONFERENCE: MEET_DOMAIN,
  VUE_APP_GATEWAY_HTTP: GATEWAY_HTTP,
  VUE_APP_GATEWAY_WS: GATEWAY_WS,
  VUE_APP_MEET_HTTP: MEET_HTTP,
  VUE_APP_MEET_WS: MEET_WS,
  VUE_APP_MEET_COMMON_KEY: COMMON_KEY,
  VUE_APP_VIDYO_IFRAME_URL: VIDYO_IFRAME_URL,
} = process.env;

export {
  COMMON_KEY,
  GATEWAY_HTTP,
  GATEWAY_WS,
  MEET_HTTP,
  MEET_WS,
  MEET_DOMAIN,
  NODE_ENV,
  VIDYO_IFRAME_URL,
};
