<template>
  <div class="d-flex justify-space-between flex-column text-center call-container">
    <vidyo-chat-container @leftMeeting="leaveMeeting" @meetingJoined="onMeetingJoined" />
  </div>
</template>
<style scoped>
.call-container {
  height: 100%;
  width: 100%;
}
</style>
<script>
import PATIENT_READY from '../graphql/Subscription/ScheduleAppointmentPatientReady.gql';
import { closeWebsockets } from '../vue-apollo';

export default {
  name: 'Call',
  components: {
    VidyoChatContainer: () => import('../components/VidyoChatContainer.vue'),
  },
  data() {
    return {
      token: null,
    };
  },

  computed: {
    canJoinCall() {
      return !!this.eventId && !!this.token;
    },
    eventId() {
      return this.$store.state.eventId;
    },
    providerId() {
      return this.$store.state.providerId;
    },
  },
  methods: {
    async getEventToken() {
      const { authToken: token } = this.$store.state;
      this.token = token;
    },
    handleKicked() {
      this.$router.push({ name: 'KickedOut' });
    },
    leaveMeeting() {
      closeWebsockets();
      this.$router.push({ name: 'LeftCall' });
    },
    onMeetingJoined() {
      this.startSubscription();
      this.$store.dispatch('setPatientReady', true);
    },
    startSubscription() {
      const observer = this.$apollo.subscribe({
        query: PATIENT_READY,
      });

      observer.subscribe({
        next() {},
        error(error) {
          console.error(error);
        },
      });
    },
  },
  mounted() {
    if (!this.eventId) {
      this.$router.push({ name: 'LeftCall' });
    }
    this.$store.commit('set', { meetContainer: { height: '650px', width: '850px' } });
    this.getEventToken();
  },
  beforeDestroy() {
    this.token = null;
  },
};
</script>
