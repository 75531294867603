import { VALID_ERROR_MESSAGES } from '../constants/errors';
// eslint-disable-next-line import/prefer-default-export
export const getErrorMessage = (errorsObject) => {
  try {
    const errors = { ...errorsObject };
    const { graphQLErrors } = errors;
    const [info] = graphQLErrors;
    const { message } = info;
    if (!VALID_ERROR_MESSAGES.includes(message)) return null;
    return message;
  } catch {
    return null;
  }
};
