import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { LIGHT_THEME } from '../constants/colors';

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: LIGHT_THEME,
    },
  },
});

export default vuetify;
