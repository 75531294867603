export default [
  {
    value: 'en',
    text: 'English',
  },
  {
    value: 'fr',
    text: 'Français',
  },
];
