<!-- eslint-disable-next-line vue/max-attributes-per-line -->
<template>
  <div class="text-center d-flex flex-column align-center justify-center">
    <div class="mt-3">
      <h2>
        {{ $t('start.welcome') }}
      </h2>

      <p class="mt-8" v-if="!isExpress">{{ $t('start.readTerms') }}</p>
      <i18n v-else path="start.readTermsOfService" tag="p" class="mt-8">
        <template v-slot:tos>
          <info-dialog>
            <template slot="open-dialog"
              ><a href="/tos" @click.prevent>{{
                $t('login.termsOfService.termsOfService')
              }}</a></template
            >

            <template slot="dialog-title">{{ $t('login.termsOfService.termsOfService') }}</template>
            <template slot="dialog-content"><terms-of-service></terms-of-service></template>
          </info-dialog>
        </template>
        <template v-slot:privacyPolicy>
          <info-dialog>
            <template slot="open-dialog"
              ><a href="/privacy" @click.prevent>{{ $t('login.privacyPolicy') }}</a></template
            >

            <template slot="dialog-title">{{ $t('login.privacyPolicy') }}</template>
            <template slot="dialog-content"><privacy-policy></privacy-policy></template>
          </info-dialog>
        </template>
      </i18n>
    </div>

    <div class="d-flex  flex-column align-center">
      <v-checkbox v-model="consent" v-if="!isExpress">
        <div slot="label" class="text-subtitle-2">
          {{ $t('login.termsOfService.read') }}
          <info-dialog>
            <template slot="open-dialog"
              ><a href="/tos" @click.prevent>{{
                $t('login.termsOfService.termsOfService')
              }}</a></template
            >

            <template slot="dialog-title">{{ $t('login.termsOfService.termsOfService') }}</template>
            <template slot="dialog-content"><terms-of-service></terms-of-service></template>
          </info-dialog>
          {{ $t('util.and') }}
          <info-dialog>
            <template slot="open-dialog"
              ><a href="/privacy" @click.prevent>{{ $t('login.privacyPolicy') }}</a></template
            >

            <template slot="dialog-title">{{ $t('login.privacyPolicy') }}</template>
            <template slot="dialog-content"><privacy-policy></privacy-policy></template>
          </info-dialog>
        </div>
      </v-checkbox>
      <div class="ma-0" v-if="!isExpress">
        <v-btn color="primary" :disabled="!completed" @click="onSubmit">{{
          $t('login.submit')
        }}</v-btn>
      </div>
      <div class="mt-4 d-flex flex-column" v-else>
        <v-btn color="primary" @click="onSubmit">{{ $t('start.accept') }}</v-btn>
        <v-btn small text class="mt-2" @click="decline">{{ $t('start.decline') }}</v-btn>
      </div>
    </div>
  </div>
</template>
<style scoped>
.logo-image-container {
  width: 100%;
}
.logo-image {
  position: relative;
  height: 65px;
  cursor: pointer;
}
.logo-image > img {
  height: 100%;
}

.meet-wrapper {
  height: 100vh;
}
.meet-container {
  width: 650px;
}
.start-wrapper {
  width: 600px !important;
}
@media all and (max-width: 768px) {
  .start-wrapper {
    max-width: 95% !important;
  }
  .meet-container {
    max-width: 95% !important;
  }
}
</style>
<script>
import InfoDialog from '../components/InfoDialog.vue';
import PrivacyPolicy from '../components/PrivacyPolicy.vue';
import TermsOfService from '../components/TermsOfService.vue';
import { restartApollo } from '../vue-apollo';

export default {
  components: { InfoDialog, PrivacyPolicy, TermsOfService },
  name: 'Start',
  data() {
    return {
      consent: false,
      password: null,
      passwordErrors: null,
    };
  },
  computed: {
    completed() {
      return this.consent;
    },
    isExpress() {
      return !!this.$store.state.express;
    },
  },
  methods: {
    decline() {
      this.$router.push({ name: 'Password', query: { express: !!this.$store.state.express } });
    },
    async onSubmit() {
      this.$router.push({ name: 'Call' });
    },
  },
  mounted() {
    restartApollo();
    this.$store.commit('set', {
      meetContainer: { height: '450px', width: '650px' },
    });
  },
};
</script>
