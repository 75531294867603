<template>
  <v-row no-gutters class="options-container mt-md-12">
    <v-col cols="12" md="6" class="d-flex justify-center video-column">
      <video class="video-container d-flex align-center justify-center" autoplay></video>
      <div v-if="videoMuted" class="video-disabled-msg text-center">
        {{ $t('options.videoDisabled') }}
      </div>
      <div v-if="audioMuted" class="muted-indicator pa-1 d-flex justify-center align-center">
        <v-icon size="50" color="white">mdi-microphone-off</v-icon>
      </div>
    </v-col>
    <v-col cols="12" md="6" class="mt-8 pl-md-8">
      <v-row class="mb-4" no-gutters>
        <v-col>
          <v-select
            label="Microphone"
            @change="getStream"
            outlined
            :items="audioInput"
            :item-value="getItemValue"
            item-text="label"
            v-model="selectedAudio"
            :disabled="audioMuted"
            hide-details
            class="microphone-select"
          >
            <template v-slot:append-outer>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :color="testingMicColor"
                    class="ml-2"
                    fab
                    v-on="on"
                    v-bind="attrs"
                    @click="toggleMicTest"
                    plain
                    ><v-icon>{{
                      isTestingMic ? 'mdi-microphone-settings' : 'mdi-microphone'
                    }}</v-icon></v-btn
                  >
                </template>
                <span>{{ isTestingMic ? $t('options.stopTestMic') : $t('options.testMic') }}</span>
              </v-tooltip>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-select
            label="Camera"
            @change="getStream"
            outlined
            :items="videoInput"
            :item-value="getItemValue"
            item-text="label"
            v-model="selectedVideo"
            :disabled="videoMuted"
            hide-details
          ></v-select>
        </v-col>
      </v-row>
      <v-row class="mb-4">
        <v-col>
          <v-checkbox
            v-model="audioMuted"
            :label="$t('options.muteMic')"
            @change="getStream"
            hide-details
          ></v-checkbox>
        </v-col>
        <v-col>
          <v-checkbox
            v-model="videoMuted"
            :label="$t('options.disableCam')"
            @change="getStream"
            hide-details
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="d-flex justify-center">
          <v-btn :disabled="!hasFullDevicePermission" @click="next">{{ $t('options.next') }}</v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<style lang="scss">
.microphone-select {
  .v-input__append-outer {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px !important;
  }
}
.muted-indicator {
  position: absolute;
  right: 10px;
  bottom: 10px;
  &::before {
    content: '';
    height: 100%;
    width: 100%;
    border: 3px solid white;
    border-radius: 50%;
    position: absolute;
  }
}
.video-container {
  width: 100%;
  height: 100%;
  background-color: black;
}
.video-disabled-msg {
  position: absolute;
  color: white;
  width: 100%;
}
.video-column {
  max-height: 350px;
}
@media all and (max-width: 600px) {
  .options-container {
    width: calc(100vw - 50px);
  }
}
</style>
<script>
export default {
  name: 'Options',
  data() {
    return {
      audioInput: [],
      audioMuted: false,
      audioOutput: [],
      audioPermission: false,
      isTestingMic: false,
      selectedAudio: null,
      selectedVideo: null,
      video: null,
      videoInput: [],
      videoMuted: false,
      videoPermission: false,
    };
  },
  methods: {
    next() {
      this.$store.commit('set', {
        userConfig: {
          audioMuted: this.audioMuted,
          videoMuted: this.videoMuted,
          audio: this.selectedAudio,
          video: this.selectedVideo,
        },
      });
      this.$router.push({ name: 'WaitingRoom' });
    },
    getDevicePermissions() {
      navigator.permissions.query({ name: 'microphone' }).then((permissionStatus) => {
        // eslint-disable-next-line no-param-reassign
        permissionStatus.onchange = (e) => {
          const {
            currentTarget: { state },
          } = e;

          this.audioPermission = state === 'granted';
        };
        const { state } = permissionStatus;
        this.audioPermission = state === 'granted';
      });
      navigator.permissions.query({ name: 'camera' }).then((permissionStatus) => {
        // eslint-disable-next-line no-param-reassign
        permissionStatus.onchange = (e) => {
          const {
            currentTarget: { state },
          } = e;
          this.videoPermission = state === 'granted';
        };
        const { state } = permissionStatus;
        this.videoPermission = state === 'granted';
      });
      this.promptForDevicePermissions();
    },
    promptForDevicePermissions() {
      navigator.mediaDevices.getUserMedia({ audio: true, video: true }).catch(() => {
        this.setErrorNotification();
      });
    },
    getDevices() {
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        devices.forEach((device) => {
          const { deviceId, groupId, kind, label } = device;
          const deviceObj = {
            deviceId,
            groupId,
            kind,
            label,
          };

          if (device.kind === 'audioinput') {
            this.audioInput = [...this.audioInput, deviceObj];
          } else if (device.kind === 'videoinput') {
            this.videoInput = [...this.videoInput, deviceObj];
          } else {
            this.audioOutput = [...this.audioOutput, deviceObj];
          }
        });
        this.selectedAudio = { ...this.audioInput[0] };
        this.selectedVideo = { ...this.videoInput[0] };
      });
    },
    getStream() {
      this.stopTracks();

      const constraints = {
        audio: !this.isTestingMic
          ? false
          : {
              deviceId: this.selectedAudio?.deviceId,
              echoCancellation: true,
            },
        video: this.videoMuted
          ? false
          : {
              deviceId: this.selectedVideo?.deviceId,
            },
      };

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(this.gotStream)
        .catch(this.handleError);
    },
    getItemValue(item) {
      const itemObj = {
        deviceId: item.deviceId,
        groupId: item.groupId,
        kind: item.kind,
        label: item.label,
      };

      return itemObj;
    },
    gotStream(stream) {
      window.stream = stream;
      this.video.srcObject = stream;
    },
    permissionPrompt({ audio = false, video = false }) {
      navigator.mediaDevices.getUserMedia({ audio, video });
    },
    resetOptions() {
      this.$store.commit('set', {
        userConfig: {
          audio: null,
          video: null,
          audioMuted: false,
          videoMuted: false,
        },
      });
    },
    setErrorNotification() {
      this.$store.dispatch('setNotification', {
        color: 'error',
        text: this.$t('options.permissionsError'),
        linkUrl: this.$t('options.permissionsLink'),
        linkText: this.$t('options.permissionsLinkText'),
      });
    },
    startDevices(permissionStatus) {
      if (permissionStatus.state === 'granted') {
        this.getDevices();
      }
      this.resetOptions();
      this.getStream();
    },
    stopTracks() {
      if (window.stream) {
        window.stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    },
    toggleMicTest() {
      this.isTestingMic = !this.isTestingMic;
      this.getStream();
    },
  },
  computed: {
    completed() {
      return this.password && this.consent;
    },
    hasFullDevicePermission() {
      return this.audioPermission && this.videoPermission;
    },
    testingMicColor() {
      return this.isTestingMic ? 'primary' : '';
    },
  },
  mounted() {
    this.getDevicePermissions();

    this.video = document.querySelector('video');
    this.$store.commit('set', { meetContainer: { height: '800px', width: '1000px' } });
  },
  beforeDestroy() {
    this.stopTracks();
  },
  watch: {
    hasFullDevicePermission(bool) {
      if (bool) {
        this.getDevices();
        this.getStream();
        this.$store.dispatch('clearNotification');
      } else {
        this.setErrorNotification();
      }
    },
  },
};
</script>
