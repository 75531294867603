import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import { PUBLIC_PATHS } from '../constants/router';

import Call from '../views/Call.vue';
import CallOver from '../views/CallOver.vue';
import CancelConfirm from '../views/CancelConfirm.vue';
import Expired from '../views/Expired.vue';
import Gateway from '../views/Gateway.vue';
import KickedOut from '../views/KickedOut.vue';
import LeftCall from '../views/LeftCall.vue';
import NoAccess from '../views/NoAccess.vue';
import Password from '../views/Password.vue';
import Pin from '../views/Pin.vue';
import Options from '../views/Options.vue';
import Start from '../views/Start.vue';
import WaitingRoom from '../views/WaitingRoom.vue';
import TermsOfService from '../views/TermsOfService.vue';
import PrivacyPolicy from '../views/PrivacyPolicy.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/call',
    name: 'Call',
    component: Call,
    meta: {
      hideLogo: true,
    },
  },
  {
    path: '/denied',
    name: 'NoAccess',
    component: NoAccess,
  },
  {
    path: '/kicked',
    name: 'KickedOut',
    component: KickedOut,
  },
  {
    path: '/end',
    name: 'CallOver',
    component: CallOver,
  },
  {
    path: '/leave',
    name: 'LeftCall',
    component: LeftCall,
  },
  {
    path: '/expired',
    name: 'Expired',
    component: Expired,
  },
  {
    path: '/',
    name: 'Password',
    component: Password,
  },
  {
    path: '/pin',
    name: 'Pin',
    component: Pin,
  },
  {
    path: '/options',
    name: 'Options',
    component: Options,
  },
  {
    path: '/start',
    name: 'Start',
    component: Start,
  },
  {
    path: '/waitingRoom',
    name: 'WaitingRoom',
    component: WaitingRoom,
  },
  {
    path: '/cancelconfirm',
    name: 'CancelConfirm',
    component: CancelConfirm,
  },
  {
    path: '/cancel',
    name: 'CancelGateway',
    component: Gateway,
  },
  {
    path: '/refer',
    name: 'Gateway',
    component: Gateway,
  },
  {
    path: '/tos',
    name: 'TermsOfService',
    component: TermsOfService,
  },
  {
    path: '/privacy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const { authToken } = store.state;
  if (PUBLIC_PATHS.includes(to.name)) {
    next();
  } else if (!from.name || !authToken) {
    next('/denied');
  }
  next();
});

export default router;
