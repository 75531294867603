export default {
  actions: {
    enterCode: 'Saisir le code',
    joinRoom: 'Rejoindre la salle virtuelle',
  },
  cancel: {
    cancelError: "Impossible d'annuler le rendez-vous. Veuillez réessayer.",
    confirm: 'Oui, je veux annuler',
    confirmTitle: 'Êtes-vous certain de vouloir annuler votre rendez-vous ?',
    cancelled: 'Votre rendez-vous a été annulé.',
    errors: {
      tooClose:
        "Le rendez-vous ne peut pas être annulé car il est prévu dans les 2 prochains jours. Veuillez nous appeler pour obtenir de l'aide :",
    },
    exit: 'Quitter le site',
  },
  helpBubble: {
    tooltip: 'Cliquez pour voir notre FAQ',
  },
  login: {
    enterPassword: 'Veuillez entrer votre mot de passe pour accéder',
    termsOfService: {
      read: "J'ai lu et j'accept vos les",
      termsOfService: "Conditions d'utilisation",
    },
    privacyPolicy: 'Politique de confidentialité',
    submit: 'Soumettre',
  },
  end: {
    back: "Rejoindre l'appel",
    left: "Déconnecté de l'appel",
  },
  errors: {
    accessDenied: 'Accès refusé',
    linkExpired: 'Le lien a expiré',
    incorrectPassword: 'Le mot de passe saisi est incorrect',
  },
  header: {
    back: 'Retour aux paramètres',
  },
  kickedOut: {
    removed: 'Vous avez été retiré de la rencontre',
  },
  options: {
    disableCam: 'Désactiver la caméra',
    muteMic: 'Couper le microrophone',
    permissionsError:
      "ERREUR : Le navigateur n'a pas accès à votre microphone et/ou à votre caméra. Veuillez les activer pour continuer.",
    permissionsLinkText: 'Comment activer le microphone et la caméra',
    stopTestMic: 'Arrêter de tester le microphone',
    testMic: 'Essai le microphone',
    next: 'Continuer',
    videoDisabled: 'Vidéo désactivée',
  },
  password: {
    enterPassword: 'Veuillez saisir votre mot de passe sécurisé.',
    error: "Un problème est survenu lors de l'accès à votre rencontre sécurisée.",
  },
  pin: {
    enterPassword: 'Veuillez saisir votre code NIP sécurisé.',
    expiredSession: 'ERREUR : Le rendez-vous a expiré.',
  },
  instructions: {
    accessAppointment:
      'Pour accéder à votre rendez-vous virtuelle, veuillez suivre le lien de rencontre qui vous a été envoyé par courriel.',
    bookAppointment: 'Veuillez contacter notre support pour prendre un autre rendez-vous.',
    completedTitle: 'Le modérateur a mis fin à cette rencontre.',
    joinAnotherMeeting: 'Rejoindre une autre rencontre.',
    readyTitle: 'Le médecin a rejoint la chambre',
    readySubtitle: "Vous pouvez maintenant rejoindre l'appel.",
    removedInfo: "L'appel est terminé ou vous en avez été retiré manuellement.",
    removedInstructions:
      'Pour rejoindre votre rendez-vous virtuelle, veuillez suivre le lien de rencontre qui vous a été envoyé par e-mail.',
    standByTitle: 'Veuillez patienter.',
    standBySubtitle:
      'Vous serez automatiquement averti lorsque le modérateur ouvrira la salle.\r\n',
  },
  sentry: {},
  serverStatus: {
    maintenance: 'Entretien',
    offline: 'Hors ligne',
    online: 'En ligne',
    scheduledMaintenance:
      "Le serveur sera hors ligne pour la maintenance planifiée jusqu'au {endTime}.",
    serverOffline:
      'Le serveur est hors ligne de manière inattendue. Veuillez patienter pendant que nos techniciens remettent les choses en ligne.',
    serverOnline: 'Le serveur est en ligne.',
    upcomingMaintenance: 'Maintenance du serveur à venir',
  },
  start: {
    readTerms: "Veuillez lire et accepter les conditions d'utilisation pour continuer",
    welcome: 'Bienvenue à votre rendez-vous sécurisé avec Sentry Health.',
  },
  waitingRoom: {
    eventScheduled: 'Cet rencontre est prévu pour',
  },
  util: {},
};
