/* eslint-disable import/no-cycle */
/* eslint-disable import/extensions */
import Vue from 'vue';
import { ApolloClient } from 'apollo-client';
import { ApolloLink, concat, split } from 'apollo-link';
import { createUploadLink } from 'apollo-upload-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import VueApollo from 'vue-apollo';
import { getMainDefinition } from 'apollo-utilities';
import { setContext } from '@apollo/client/link/context';
import { createClient } from 'graphql-ws';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { AUTH_TOKEN, MEETING_INFO } from './constants/storage';
import { GATEWAY_WS, GATEWAY_HTTP } from './constants/env';
import store from './store/index';

// Install the vue plugin
Vue.use(VueApollo);
// Http endpoint
const httpEndpoint = `${GATEWAY_HTTP}/graphql`;
// Files URL root
export const filesRoot = httpEndpoint.substr(0, httpEndpoint.indexOf('/graphql'));

Vue.prototype.$filesRoot = filesRoot;

const httpLink = createUploadLink({
  uri: httpEndpoint,
});

const authMiddleware = new ApolloLink((operation, forward) => {
  // No version check on subscriptions
  const definition = getMainDefinition(operation.query);
  return forward(operation).map((response) => {
    if (definition.kind === 'OperationDefinition' && definition.operation === 'subscription') {
      return response;
    }

    return response;
  });
});
// WS connection to API
const wsClient = createClient({
  url: GATEWAY_WS,
  lazy: true,
  shouldRetry: () => true,
  connectionParams: async () => {
    let params = {};
    const token = await localStorage.getItem(AUTH_TOKEN);
    const meetInfo = await localStorage.getItem(MEETING_INFO);
    if (token) params = { ...params, Authorization: `Bearer ${token}` };
    if (meetInfo) params = { ...params, scheduleAppointmentPatientReady: meetInfo };
    return params;
  },
});
wsClient.on('connected', () => {
  store.dispatch('setPatientReady', true);
});
const wsLink = new GraphQLWsLink(wsClient);

const cache = new InMemoryCache();

const authLink = setContext(() => {
  return {};
});
const link = split(
  // split based on operation type
  (operation) => {
    const definition = getMainDefinition(operation.query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink
);

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: concat(authMiddleware, authLink.concat(link)),
  cache,
});

export function createProvider() {
  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
      $mutate: {
        errorPolicy: 'all',
      },
    },
    clients: {
      default: apolloClient,
    },
  });

  return apolloProvider;
}

export const restartApollo = () => {
  apolloClient.cache.data.clear();
};
export const closeWebsockets = () => {
  wsLink.client.dispose();
};
