<template>
  <terms-of-service />
</template>
<script>
import TermsOfService from '../components/TermsOfService.vue';

export default {
  components: {
    TermsOfService,
  },
};
</script>
