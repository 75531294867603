<template>
  <div
    style="height: 300px; width: 500px;"
    class="d-flex justify-center align-center flex-column"
  />
</template>

<script>
import moment from 'moment';
import { AUTH_TOKEN } from '../constants/storage';
import { MEET_DOMAIN } from '../constants/env';
import { parseJwt } from '../utils/validation';

import GET_MEET_TOKEN from '../graphql/Query/PublicOTNTokenExchange.gql';
import PUBLIC_LINK_EXCHANGE from '../graphql/Query/PublicLinkExchange.gql';

export default {
  name: 'Gateway',
  data() {
    return {
      token: null,
    };
  },
  computed: {
    checkValidLink() {
      const { appointmentId, providerId } = this.params;
      return !!appointmentId && !!providerId && !!this.token;
    },
    checkExpired() {
      try {
        const { token } = this;
        const { exp } = parseJwt(token);
        return moment().unix() > exp;
      } catch {
        return true;
      }
    },
    facilitationToken() {
      return this.$route.query.t;
    },
    isNewLink() {
      return !this.token || this.token !== localStorage.getItem(AUTH_TOKEN);
    },
    isCancellation() {
      return this.$route.name === 'CancelGateway';
    },
    isExpress() {
      return !!this.$route.query.express;
    },
    isTrt() {
      const { appointmentId } = this.params;
      return appointmentId === 'trt';
    },
    params() {
      try {
        const { providerId, room: appointmentId } = parseJwt(this.facilitationToken);
        return {
          appointmentId,
          providerId,
        };
      } catch {
        return null;
      }
    },
    participantLink() {
      const { appointmentId } = this.params;
      return `${MEET_DOMAIN}/${appointmentId}`;
    },
    linkId() {
      return this.$route.query.link;
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      const { isExpress } = this;
      this.$store.commit('set', {
        loading: true,
        meetContainer: { height: '450px', width: '650px' },
      });
      const pinEnabled = await this.getMeetToken();
      if (pinEnabled) return;
      const urlType = this.checkUrl();
      if (urlType === 'cancellation') {
        this.onCancellationLink();
      } else if (urlType === 'linkId') {
        this.onLinkId();
      } else if (urlType === 'referral') {
        this.onReferralLink();
      } else if (urlType === 'trt') {
        this.$router.push({ name: 'Password' });
      } else if (urlType === 'expired') {
        this.$router.push({ name: 'Expired' });
      } else {
        // if no token, not TRT, not referral, not expired

        this.$router.push({ name: 'NoAccess' });
      }
      setTimeout(() => {
        this.$store.commit('set', {
          loading: false,
          express: isExpress,
        });
      }, 0);
    },
    checkUrl() {
      if (this.isCancellation) return 'cancellation';
      if (this.linkId) return 'linkId';
      if (this.checkExpired) return 'expired';
      const { appointmentId, providerId } = this.params;
      if (appointmentId && providerId && this.checkValidLink) return 'referral';

      if (this.isTrt) return 'trt';
      return null;
    },
    async getMeetToken() {
      if (this.isCancellation) {
        this.token = this.facilitationToken;
        return false;
      }
      try {
        const { facilitationToken } = this;
        const result = await this.$apollo.query({
          query: GET_MEET_TOKEN,
          variables: {
            facilitationToken,
          },
          fetchPolicy: 'no-cache',
        });
        const { meetToken, pinEnabled } = result.data.publicOTNTokenExchange;
        if (pinEnabled) {
          this.onPinEnabled();
          return true;
        }
        if (meetToken) {
          this.token = meetToken;
        }
      } catch {
        this.token = null;
      }
      return false;
    },
    async onCancellationLink() {
      if (this.isNewLink) this.$store.dispatch('resetSession');
      if (!this.token) {
        this.$router.push({ name: 'NoAccess' });
      } else {
        const facilitationToken = await this.publicLinkExchange(this.facilitationToken);
        this.$store.commit('set', {
          authToken: facilitationToken,
          facilitationToken,
        });
        this.$router.push({ name: 'CancelConfirm' });
      }
    },
    async publicLinkExchange(linkId) {
      const data = await this.$apollo.query({
        query: PUBLIC_LINK_EXCHANGE,
        variables: {
          linkId,
        },
        fetchPolicy: 'no-cache',
      });
      const { publicLinkExchange: t } = data.data;
      return t;
    },
    async onLinkId() {
      try {
        const { linkId } = this;
        const t = await this.publicLinkExchange(linkId);
        this.$router.push({ name: 'Gateway', query: { t } });
        this.init();
      } catch {
        this.$router.push({ name: 'NoAccess' });
      }
    },
    onPinEnabled() {
      this.setItems();
      this.$router.push({ name: 'Pin' });
    },
    onReferralLink() {
      if (this.isNewLink) this.$store.dispatch('resetSession');
      if (this.checkValidLink) {
        localStorage.setItem(AUTH_TOKEN, this.token);
        this.setItems();
        this.$router.push({ name: 'Start' });
      } else {
        this.$router.push({ name: 'NoAccess' });
      }
    },
    setItems() {
      this.$store.commit('set', {
        authToken: this.token,
        facilitationToken: this.facilitationToken,
        appointmentId: this.params?.appointmentId,
        eventId: `${this.params?.appointmentId}`,
        providerId: this.params?.providerId,
      });
      this.$store.commit('set', {
        participantLink: this.participantLink,
      });
    },
  },
};
</script>
