export const BRAND_COLORS = {
  accent: '#38dfff',
  accent2: '#b7f5fb',
  cta: '#48d04c',
  deepBlue: '#050a30',
  emblems: '#2b8aff',
  error: '#EF5350',
  lightBlue: '#34b6f8',
  lightTeal: '#9bd4e4',
};

export const LIGHT_THEME = {
  accent: BRAND_COLORS.deepBlue,
  error: BRAND_COLORS.error,
  info: BRAND_COLORS.lightBlue,
  primary: BRAND_COLORS.emblems,
  secondary: BRAND_COLORS.accent,
  success: BRAND_COLORS.cta,
  warning: '#FFC107',
};
