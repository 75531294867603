<template>
  <div class="meeting-ended-container d-flex align-center flex-column mt-7">
    <div>
      <h2 class="text-center">{{ $t('end.left') }}</h2>
    </div>
    <div class="mt-10 d-flex flex-column">
      <v-btn color="primary" @click="backToCall">{{ $t('end.back') }}</v-btn>
      <v-btn color="primary" @click="leave" class="mt-4">{{
        $t('instructions.joinAnotherMeeting')
      }}</v-btn>
    </div>
  </div>
</template>
<style scoped>
.meeting-ended-container {
  height: 100%;
}
</style>
<script>
export default {
  name: 'LeftCall',

  data() {
    return {
      meeting: true,
    };
  },
  computed: {
    appointmentId() {
      return this.$store.state.appointmentId;
    },
    token() {
      return this.$store.state.authToken;
    },
  },
  methods: {
    backToCall() {
      this.$router.push({ name: 'Call' });
    },
    async leave() {
      this.$router.push({ name: 'Password', query: { express: !!this.$store.state.express } });
    },
  },
  mounted() {
    this.$store.commit('set', {
      meetContainer: { height: '450px', width: '650px' },
    });
  },
};
</script>
