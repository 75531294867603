<template>
  <div class="c13">
    <p class="c9"><span class="c4">Sentry Health Inc.</span></p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c9"><span class="c4">Privacy Policy</span></p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2"><span>Last modified: </span><span class="c4 c7">2021-06-24</span></p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2"><span>Effective date: </span><span class="c4 c7">2021-06-24</span></p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2 c3"><span class="c8 c4"></span></p>
    <p class="c2">
      <span class="c4">1. </span><span>&nbsp;</span><span class="c4">TERMS OF USE</span>
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span
        >This privacy policy as well as our Terms of Use found here:
        Sentryhealth.ca/terms-of-use</span
      ><span class="c4 c7">&nbsp;</span><span>(&ldquo;</span><span class="c4">Terms of Use</span
      ><span
        >&rdquo;) govern your access of the Sentry Health platform, website, and , whether: (a)
        accessed on a computer connected to the internet at Sentryhealth.ca (the &ldquo;</span
      ><span class="c4">Site</span
      ><span
        >&rdquo;); (b) on the Sentry Health social media properties; or (c) by mobile phone
        (individually and collectively, (a), (b) and (c) are the &ldquo;Product and
        Services&rdquo;), as owned and operated by Sentry Health, an Ontario corporation having its
        registered address at 511-43 Hanna Avenue, Toronto Ontario M6K 1X1. Sentry Health Inc.
        (referred to in these Terms as &ldquo;</span
      ><span class="c4">we</span><span>&rdquo;, &ldquo;</span><span class="c4">us</span
      ><span>&rdquo; or the &ldquo;</span><span class="c4">Company</span
      ><span class="c0"
        >&rdquo;). Terms capitalized but not defined in this Privacy Policy have the meanings set
        out in the Terms of Use.</span
      >
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c4">2. </span><span>&nbsp; &nbsp;</span
      ><span class="c4">INTRODUCTION TO PRIVACY POLICY</span>
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span
        >Sentry Health is committed to maintaining the confidentiality, integrity and security of
        any Personal Information (as defined below) about our subscribers to the Site. The Company
        strives to support individuals and business owners with the Product and Services, while
        respecting your privacy expectations and protecting your Personal Information. To
        demonstrate our commitment to protecting your privacy, we have developed this privacy policy
        (&ldquo;</span
      ><span class="c4">Privacy Policy</span
      ><span class="c0"
        >&rdquo;), which describes how we will collect, use, disclose and protect your Personal
        Information through the Site. By accessing and/or using the Product and Services, you agree
        to all the terms and conditions of this Privacy Policy and the Terms of Use found here:
        Sentryhealth.ca/terms-of-use and which are incorporated here by reference. If you do not
        agree to all the terms and conditions of this Privacy Policy and the Terms of Use, do not
        use the Product and Services.</span
      >
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c4">3. </span><span>&nbsp; &nbsp;</span
      ><span class="c4">AMENDMENTS TO THIS PRIVACY POLICY</span>
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span
        >The Company may amend or change this Privacy Policy at its sole discretion at any time, and
        in accordance with the amendment provisions set out in the Terms of Use. The use of the
        information we collect at any given point is subject to the Privacy Policy in effect at the
        time of collection. If we make any material changes, we will notify you by email or by means
        of notice on the Product and Services prior to the change becoming effective</span
      ><span class="c4 c7">.</span
      ><span class="c0"
        >&nbsp;We will post the most current Privacy Policy on the Product and Services, and your
        use of the Product and Services is subject to the most current Privacy Policy as posted on
        the Product and Services at any time. We encourage you to periodically check our Privacy
        Policy for the latest information on our current policy.</span
      >
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c4">4. </span><span>&nbsp;</span><span class="c4">CONTACT INFORMATION</span>
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span
        >If you have questions or concerns regarding our policy or practices, please contact our
        privacy officer, Victor Nguyen,</span
      ><span class="c4 c7">&nbsp;</span><span class="c0">by email at victor@sentryhealth.ca.</span>
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c0"
        >If you do not receive acknowledgment of your inquiry or your inquiry has not been
        satisfactorily addressed, you can contact the Ontario Privacy Commissioner at:
      </span>
    </p>
    <ol class="c6 lst-kix_list_3-0 start" start="1">
      <li class="c2 c5 c11 li-bullet-0">
        <span class="c0">2 Bloor St E, Toronto, Ontario, Canada, M4W 1A8; or</span>
      </li>
      <li class="c2 c5 c11 li-bullet-0"><span class="c0">info@ipc.on.ca. </span></li>
    </ol>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c4">5. </span><span>&nbsp; &nbsp;</span><span class="c4">GENERAL</span>
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span
        >&#65279;We may provide areas on our websites where you can post information about yourself
        and others and communicate with others or upload content. Such postings are governed by the
        Company&#39;s Terms of Use. In addition, such postings may appear on other Product and
        Services or when searches are executed on the subject of your posting. We cannot control who
        reads your posting or what other users may do with the information you voluntarily post.
        Therefore, we encourage you to exercise discretion and caution with respect to your personal
        information</span
      ><span class="c4 c7">.</span>
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c4">6. </span><span>&nbsp; &nbsp;</span
      ><span class="c4">COLLECTION OF PERSONAL INFORMATION</span>
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c4">6.1. </span><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
      ><span class="c4">Collection of information at registration</span>
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2 c5">
      <span>Registration is required if you want to use the Product and Services. </span
      ><span class="c4 c7">[Health Care Providers may register at sentryhealth.ca</span
      ><span class="c4 c7"
        >. Other users may either sign in through sentryhealth.ca and other social media sites or
        register using an online form located at sentryhealth.ca.]</span
      ><span
        >&nbsp;As part of this registration, we will require that you submit certain information
        that is relevant to the purposes of the Product and Services. We collect the following
        information (&ldquo;</span
      ><span class="c4">Personal Information</span
      ><span
        >&rdquo;) from our subscriber&rsquo;s registration information: first and last name, date of
        birth, gender, phone number, email address, and password</span
      ><span class="c4 c7">.</span>
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c4">6.2. </span><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
      ><span class="c4">Mobile Device Location Information</span>
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2 c5">
      <span class="c0"
        >Upon registration or the use of our location-enabled services on the Product and Services
        (for example, when you access services from a mobile device), we may need to collect and
        process information about your actual Global Positioning System location (including the
        latitude, longitude or altitude of your mobile device) and the time the location information
        was recorded. Some of these services require your personal data for the feature to work and
        we may associate location data with your device identification and other information we hold
        about you. If you wish to use the particular feature, you will be asked for your consent. If
        you do not want your location information collected when you use the Product and Services,
        please contact your device manufacturer or platform provider to determine how to disable the
        collection of this information.</span
      >
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c4">6.3.</span><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
      ><span class="c8 c4">Collection of information when you use the Product and Services</span>
    </p>
    <p class="c2 c3"><span class="c8 c4"></span></p>
    <p class="c2 c5">
      <span class="c0"
        >When you use the Product and Services, we automatically collect and store certain
        information about your computer or device and your activities including:
      </span>
    </p>
    <ol class="c6 lst-kix_list_3-1 start" start="1">
      <li class="c2 c10 li-bullet-0"><span class="c0">the IP address of your computer; </span></li>
      <li class="c2 c10 li-bullet-0">
        <span class="c0">the unique mobile device identifier; </span>
      </li>
      <li class="c2 c10 li-bullet-0">
        <span class="c0"
          >technical information about your computer or mobile device such as they type of device,
          mobile device identification number, web browser, other browser information (e.g. size,
          connection speed and connection type), and operating system or platform;
        </span>
      </li>
      <li class="c2 c10 li-bullet-0">
        <span class="c0">your preferences and settings (time zone, language, etc.); </span>
      </li>
      <li class="c2 c10 li-bullet-0">
        <span class="c0">demographic information; platform use information and analytics; and</span>
      </li>
      <li class="c2 c10 li-bullet-0">
        <span class="c0">your internet provider or mobile carrier name.</span>
      </li>
    </ol>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c4">6.4. </span><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
      ><span class="c4">Collection of information submitted by you</span>
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2 c5">
      <span class="c0"
        >You may send electronic information or physical documentation to us in the following forms:
        messages or transaction information relating to your interactions with our Health Care
        Providers (as defined in the Terms of Use); receipts, personal data in text and picture
        form; and other subscriber-generated content provided to us in the normal course of your use
        of the Product and Services, including but not limited to posts, profiles, comments,
        suggestions, forwarded messages, feedback information, usage information, transaction
        information and Traffic Data (as defined below).</span
      >
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c4">6.5. </span><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
      ><span class="c4">Collection through interaction with the Product and Services</span>
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2 c5">
      <span class="c0"
        >We may collect your Personal Information when you communicate it to us by use of one of our
        web forms or other interactions with the Product and Services, by email, or by any other
        means of communication.</span
      >
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2 c5">
      <span
        >The Product and Services offers publicly-accessible blogs and allows you to create personal
        profiles which can be seen by others (collectively, </span
      ><span class="c4">&ldquo;Public Content</span
      ><span class="c0"
        >&rdquo;). This Public Content and any of the information you post to the Site, such as
        reviews, are intended for public consumption. You should be aware that any information you
        provide in this area may be read, collected, and used by others who access them. We take no
        responsibility for any information which you or your employees, agents, contractors and
        representatives post or publish on the Product and Services and you agree to indemnify and
        hold us harmless for any loss, cost, complaint, damage, claim or liability whatsoever
        arising from any such post or publication. We may post your testimonials along with your
        name on the Product and Services from time to time if such information is provided to
        us.</span
      >
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2 c5">
      <span class="c4 c7">[If you are logged into social media sites such as </span
      ><span class="c4 c7"
        >sentryhealth.ca, and you access but are not logged into the Product and Services, we may
        receive information from such social media site to make it easier for you to create an
        account on the Product and Services and show you relevant content from your friends on such
        social media. This information personalizes your experiences and helps you create an
        account. You can also connect your account on third party social media like sentryhealth.ca,
        in which case we may collect and store information identifying your account with the
        third-party service.]</span
      >
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2 c5">
      <span class="c0"
        >We may collect and store information (including personal information) locally on your
        device using mechanisms such as browser web storage (including HTML 5) and application data
        caches.</span
      >
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c4">6.6. </span><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
      ><span class="c4">Traffic Data</span>
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2 c5">
      <span>&quot;</span><span class="c4">Traffic Data</span
      ><span class="c0"
        >&quot; is collected by the Product and Services and our third-party traffic analysis
        provider through the use of software that is included on the Product and Services as well as
        cookies that are stored temporarily on your computer. Together, these technologies help us
        manage our content by identifying which content is effective.</span
      >
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c4">6.7. </span><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
      ><span class="c4">Use of Cookies and Usage Data</span>
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2 c5">
      <span
        >Cookies are small data files that are stored on a subscriber&rsquo;s computer for
        record-keeping purposes. Cookies track where you travel on the Product and Services and what
        you look at. A session ID cookie expires when you close your browser. When you log into the
        Product and Services, your browser may ask if you want it to remember you as a registered
        subscriber of the Product and Services. If you accept, the session ID becomes a persistent
        cookie, which remains on your hard drive for an extended period of time. Although cookies
        are used by most major websites and are accepted by default by most web browsers, it may be
        possible to disable cookies via your browser settings. We use session cookies and usage data
        to make it possible to navigate the secure environment inside the Product and Services and
        to keep, and periodically track, information about you for the purpose of creating a
        personalized web experience and improving the Product and Services</span
      ><span class="c4 c7">&nbsp;</span
      ><span class="c0"
        >or measuring and conveying to others the performance levels of the Product and Services. We
        may link the information we store in cookies to any personally identifiable information you
        submit while on the Product and Services. This is for the purpose of creating a personalized
        experience within the Product and Services, to assist the Company in understanding its users
        and subscribers and designing improvements to the Product and Services, and for the purpose
        of collecting usage and performance metrics.</span
      >
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c4">6.8. </span><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
      ><span class="c4">Online and Mobile Advertising</span>
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2 c5">
      <span class="c0"
        >We may allow third parties to use cookies on the Product and Services to collect the same
        type of information for the same purposes as we do. Advertisers and advertising networks
        place advertisements on our websites and mobile applications. These companies as well as
        data analytics companies who service them may collect information, such as your
        computer&rsquo;s IP address, browser information, mobile device ID, and search queries as
        you use the Product and Services. These companies also may use cookies and other
        technologies to collect data about you when you visit our Site. Cookies from advertising
        companies enable them to track your activity across various websites where they display
        advertisements and record and associate your activities, so they can show advertisements
        that they consider relevant to you. We do not have access to or control over the cookies
        that these third-parties use.</span
      >
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c4">6.9. </span><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
      ><span class="c4">Use of analytics tools</span>
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2 c5">
      <span class="c0"
        >The Product and Services may from time to time implement other third-party analytics
        services that also use cookies. We will ensure that no personally identifiable information
        is included in those cookies. If the use of cookies by any service provider differs
        materially from the practices already listed, we will revise this document accordingly and
        notify existing customers of the change(s). Other cookies created by the Product and
        Services are used to secure your login session and to help ensure the security of your
        account. Such cookies are unrelated to the aforementioned traffic analysis and are never
        shared with third parties.</span
      >
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c4">6.10. </span><span>&nbsp;</span><span class="c4">&nbsp;Location Data</span>
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2 c5">
      <span
        >When you access the Product and Services using a web browser or other application, we may
        gather and store certain types of information including: traffic data from your browser,
        your IP address, location,</span
      ><span class="c4 c7">&nbsp;</span><span>GPS signals sent by a mobile device</span
      ><span class="c4 c7">,</span
      ><span class="c0"
        >&nbsp;cookie information, and the pages you visit on the Product and Services. Traffic data
        is helpful in identifying and fixing problems with the Product and Services.</span
      >
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c4">7. </span><span>&nbsp; &nbsp;</span
      ><span class="c4">USE AND DISCLOSURE OF PERSONAL INFORMATION</span>
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c4">7.1. </span><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
      ><span class="c4">General use</span>
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2 c5">
      <span class="c0"
        >The Company will not use or disclose Personal Information for purposes other than the
        identified purposes of the Product and Services. We may use your Personal Information
        (defined above) to:</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <ol class="c6 lst-kix_list_5-0 start" start="1">
      <li class="c2 c10 li-bullet-0">
        <span class="c0">provide services to our Health Care Providers </span>
      </li>
      <li class="c2 c10 li-bullet-0"><span class="c0">enforce our Terms of Use; </span></li>
      <li class="c2 c10 li-bullet-0">
        <span class="c0"
          >provide customer service and support, administrative messages, resolve disputes, and
          troubleshoot problems including helping third-party service providers fulfill their
          functions;</span
        >
      </li>
      <li class="c2 c10 li-bullet-0">
        <span class="c0">fulfill your requests for certain features of Product and Services;</span>
      </li>
      <li class="c2 c10 li-bullet-0">
        <span class="c0">customize, measure and improve the Product and Services;</span>
      </li>
      <li class="c2 c10 li-bullet-0">
        <span class="c0"
          >offer or provide you with products or services including providing you information
          relating to receipts, technical notices, updates and security alerts;</span
        >
      </li>
      <li class="c2 c10 li-bullet-0">
        <span class="c0"
          >inform you of targeted marketing, service updates, and promotional offers unless you opt
          out;</span
        >
      </li>
      <li class="c2 c10 li-bullet-0">
        <span class="c0"
          >assist us to measure our performance and to share performance information with
          others;</span
        >
      </li>
      <li class="c2 c10 li-bullet-0">
        <span class="c0"
          >comply with legal or regulatory requirements (as described below); and</span
        >
      </li>
      <li class="c2 c10 li-bullet-0">
        <span class="c0">fulfill other purposes, subject to your explicit consent</span>
      </li>
    </ol>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c4">7.2. </span><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
      ><span class="c4">Third-party disclosure</span>
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2 c5">
      <span class="c0"
        >We may share personal information or feedback with affiliates, third-party vendors,
        consultants and other service providers who work for us. We may share your first name and
        comments or feedback with third-parties. We will not tie any other information, other than
        your first name, that can identify you to your comments or feedback. However, third-parties
        may be able to tell who you are from your comments, particularly if you provide your full
        name or your contact information in the comments.</span
      >
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c4">7.3. </span><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
      ><span class="c4">Surveys</span>
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2 c5">
      <span class="c0"
        >From time to time, the Company may offer you the opportunity to participate in contests,
        giveaways and other promotions. Any information submitted in connection with such activities
        will be treated in accordance with this Privacy Policy, except as specifically set forth in
        the rules for those contests, giveaways or promotions. From time to time, the Company may
        also ask you to participate in surveys designed to help us improve the Product and Services.
        Any Personal Information provided to the Company in connection with any survey will be used
        only in relation to that survey and as elsewhere set forth in this Policy.</span
      >
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c4">7.4. </span><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
      ><span class="c4">Acquisition of the Company</span>
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2 c5">
      <span class="c0"
        >In the event that the Company, or all or a portion of our business, or one or more of its
        divisions, is acquired by one or more third parties as a result of an acquisition, merger,
        sale, reorganization, consolidation, liquidation or another similar transaction, your
        Personal Information shall be one of the transferred assets.</span
      >
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c4">7.5. </span><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
      ><span class="c4">Retaining Information</span>
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2 c5">
      <span class="c0"
        >We may retain your personal information while you have an account with us and thereafter
        for as long as we need it for purposes not prohibited by applicable laws and subject to the
        provisions in our Terms.</span
      >
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c4">7.6. </span><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
      ><span class="c4">Legally compelled disclosures</span>
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2 c5">
      <span class="c0"
        >Notwithstanding the foregoing, the Company reserves the right (and you authorize the
        Company) to share or disclose your Personal Information when the Company determines, at its
        sole discretion, that the disclosure of such information is necessary or appropriate:</span
      >
    </p>
    <ol class="c6 lst-kix_list_6-0 start" start="1">
      <li class="c2 c10 li-bullet-0">
        <span class="c0"
          >to enforce our rights against you or in connection with a breach by you of this Privacy
          Policy or the Terms of Use;</span
        >
      </li>
      <li class="c2 c10 li-bullet-0">
        <span class="c0"
          >to investigate or respond to suspected illegal or fraudulent activity or to protect the
          safety, rights, or property of us, our users, or others;</span
        >
      </li>
      <li class="c2 c10 li-bullet-0">
        <span class="c0">to prevent prohibited or illegal activities; or</span>
      </li>
      <li class="c2 c10 li-bullet-0">
        <span class="c0"
          >when required by any applicable law, rule, regulation, subpoena, or other legal
          process.</span
        >
      </li>
    </ol>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c4 c8"
        >7.7. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To provide services to our Health Care
        Providers</span
      >
    </p>
    <p class="c2 c3"><span class="c8 c4"></span></p>
    <p class="c2 c5">
      <span class="c0"
        >The Company makes its Website and the Product and Services available to Health Care
        Providers for a variety of uses, as described in our Terms of Use. In order to provide the
        Product and Services to our Health Care Providers, the Company collects certain Personal
        Information such as contact and registration information from the Health Care Provider
        employees signing up on behalf of the Health Care Providers.</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2 c5">
      <span class="c0"
        >For Health Care Provider users who register to use our Product and Services, we will
        collect Personal Information related to the Health Care Providers, including business
        contact name, email, phone number, address, as well names of practitioners and their
        specialties. We collect this Personal Information to connect Health Care Providers with
        prospective patients and clients.</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2 c5">
      <span
        >When Health Care Providers Users engage the Company to provide Product and Services, we
        collect and analyze data related to our Health Care Providers customers on their behalf,
        such as: Appointments, name and contact information, fee schedules, communications (such as,
        interactions, telephone and video calls).Some of the Personal Information received by
        Company in connection with the Product and Services may be provided by Health Care Providers
        that are subject to health information data protection laws and regulations. In the United
        States, this includes rules issued under the Health Insurance Portability and Accountability
        Act of 1996 (HIPAA) and Health Information Technology for Economic and Clinical Health Act
        of 2009 (HITECH), that govern the use and disclosure of certain individually identifiable
        health-related Personal Information (&ldquo;</span
      ><span class="c4">Protected Health Information</span
      ><span
        >&rdquo;) by covered entities and their business associates. When we receive Protected
        Health Information, we may do so as a &ldquo;</span
      ><span class="c4">Business Associate</span
      ><span class="c0"
        >&rdquo; of the Health Care Providers under an agreement that, among other things, generally
        prohibits us from using or disclosing the Protected Health Information in ways that are not
        permissible by the Health Care Providers themselves, and requires us to implement certain
        measures to safeguard the confidentiality, integrity, and availability of the Protected
        Health Information. When we act as a Business Associate, we may be subject to certain laws
        and regulations, including certain HIPAA rules, that govern our use and disclosure of
        Protected Health Information and that may be more restrictive than otherwise provided in
        this Privacy Policy.</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2 c5">
      <span class="c0"
        >In this role, the Company is a data processor, processing data upon instruction from such
        Health Care Provider in order to provide the Product and Services. This data is shared only
        with the Health Care Providers.</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2 c5">
      <span class="c0"
        >In Canada, the Company is a service provider and may act as a service provider, information
        manager or electronic service provider under applicable privacy legislation, including
        Ontario&rsquo;s Personal Health Information Protection Act (PHIPA) regulations.</span
      >
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c8 c4"
        >7.8. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Improvement of the Product and
        Service</span
      >
    </p>
    <p class="c2 c3"><span class="c8 c4"></span></p>
    <p class="c2 c5">
      <span class="c0"
        >To improve our products and services the Company uses demographic information collected
        about our users for our own business purposes, including forecasting demand for our products
        and services, improving our products and services and creating a personalized experience
        through targeted offers. Personal Information is not shared with third parties for these
        purposes.</span
      >
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c4">8. </span><span>&nbsp; &nbsp;</span
      ><span class="c4">DISCLOSURE OF PAYMENT CARD INFORMATION</span>
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c0"
        >To use certain services on the Product and Services, we may require credit or debit card
        account information. By submitting your credit or debit card account information through the
        Product and Services, you expressly consent to sharing of your information with third-party
        payment processors, other third-party service providers, and applicable businesses.</span
      >
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c4">8.1. </span><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
      ><span class="c4">DATA RETENTION AND ACCOUNT TERMINATION</span>
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2 c5">
      <span class="c0"
        >Businesses may terminate their account by pressing the unsubscribe button located in the
        settings tab of their business dashboard panel. After thirty (30) days of receiving a
        termination request, the business profile, including all offered promotions, will be removed
        from the Product and Services, but we may retain information about you for the purposes
        authorized under this Privacy Policy and our Terms unless prohibited by law. For example, we
        may retain information to prevent, investigate, or identify possible wrongdoing in
        connection with the Product and Services or to comply with legal obligations.</span
      >
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c4">8.2. </span><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
      ><span class="c4">CONSENT AND PRIVACY SETTINGS</span>
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2 c5">
      <span class="c0"
        >By using the Product and Services, you consent to the collection, use and disclosure of
        your Personal Information by us in the manner described in this Privacy Policy. You may
        always opt not to disclose certain Personal Information, but which may restrict access
        certain features of the Product and Services. For example, your name and email address are
        necessary to complete the registration process. At any time after registration, you may opt
        out of most email communication from us by clicking on the opt-out link at the bottom of our
        emails, or by contacting us at the contact details listed above. However, we may still
        contact you for administrative purposes. Withdrawing consent will not apply to actions the
        Company has already taken based on your prior consent.</span
      >
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2 c5">
      <span class="c0"
        >By providing your mobile phone number, businesses expressly consent to receive direct dial
        calls, from us for any urgent and administrative issues. When you sign up for an account,
        you are opting in to receive emails and notifications from the Product and Services and
        other Product and Services users such as businesses signed up with the Product and
        Services.</span
      >
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c4">9. </span><span>&nbsp; &nbsp;</span
      ><span class="c4">ACCURACY OF INFORMATION AND INDIVIDUAL ACCESS</span>
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2 c5">
      <span
        >The Company relies on you to ensure that the Personal Information you enter into our system
        is as accurate, complete and up-to-date as necessary for the purposes for which it is to be
        used. You may make changes or corrections to your Personal Information at any time by
        request to the Company via email and contact information, as provided herein. </span
      ><span class="c4 c7"
        >[You may review or update your personal information by clicking your settings tab in your
        dashboard or in the menu located within your personal profile.]</span
      >
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2 c5">
      <span class="c0"
        >When updating your personal information, we may ask you to verify your identity before we
        can act on your request. Unless required by law, we may reject requests that are
        unreasonably repetitive, require disproportionate technical effort, risk the privacy of
        others, or would be extremely impractical. Where we can provide information access and
        correction, and when required by law, we will do so for free.</span
      >
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2 c5">
      <span class="c0"
        >We also make every effort to ensure the accuracy of the information in our reports,
        displays, articles and support queries. However, you must verify all information created
        from your use of the Product and Services. We recommend that you consult a professional
        before completing any government or regulatory filing or otherwise relying upon the
        information, as the use of this information is at your own risk. You are responsible for
        ensuring that the information you have entered into our system is accurate, reliable and
        complete.</span
      >
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c4">10. </span><span>&nbsp;</span
      ><span class="c4">HEALTH CARE PROVIDER&rsquo;S ACCESS FOR YOUR EMPLOYEES OR CONTRACTORS</span>
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span
        >If you are a Health Care Provider, in order for you to use certain features of the Product
        and Services, you may be required to provide Personal Information relating to your
        employees, agents, contractors and representatives (&ldquo;</span
      ><span class="c4">Company Representatives</span
      ><span class="c0"
        >&rdquo;) from time to time. You acknowledge, and represent to us that you will not share
        your subscriber identification, and rather that your Company Representatives shall register
        for their own subscriber accounts on the Product and Services. You agree to indemnify and
        hold us harmless for any loss, cost, complaint, damage, claim or liability whatsoever
        arising from your disclosure to us of Personal Information relating to your Company
        Representatives to enable us to set up a user id for such Company Representatives.</span
      >
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c4">11. </span><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span
      ><span class="c4">THIRD PARTY SERVICE PROVIDERS</span>
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span
        >We may share your Personal Information with service providers who help us to run our
        operations or to otherwise fulfill your request or as required by law. Our service providers
        are restricted from using your Personal Information in any way other than for the service
        they are providing. We ensure that such third parties maintain reasonable and appropriate
        safeguards. You own your data in accordance with the rules for subscriber generated content
        set out in the Terms of Use Sentryhealth.ca/terms-of-use</span
      ><span class="c4 c7">&nbsp;</span
      ><span class="c0"
        >and can download your own data via the Product and Services at any time.</span
      >
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c4">12. </span><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span
      ><span class="c4">BUSINESS TRANSFERS</span>
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c0"
        >We may share information from or about you with subsidiaries, joint ventures, or other
        companies under common control, in which case we will require them to honour this Privacy
        Policy.</span
      >
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c4">13. </span><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span
      ><span class="c4">LINKS TO OTHER WEBSITES</span>
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span
        >Links to sites controlled or operated outside of our domain (each individually a
        &ldquo;</span
      ><span class="c4">Third-Party Site</span><span>&rdquo; and collectively the &ldquo;</span
      ><span class="c4">Third Party Sites</span
      ><span class="c0"
        >&rdquo;) are provided as a convenience to you only and do not imply an endorsement by us of
        a Third-Party Site or the company it purports to represent. We do not assume any
        responsibility for information and materials found on, or the privacy practices of, a
        Third-Party Site. This Privacy Policy does not apply to a Third-Party Site.</span
      >
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c4">14. </span><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span
      ><span class="c4">SECURITY MEASURES</span>
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c0"
        >We take your privacy very seriously. Our security measures strive to adhere to PHIPA
        applicable legislative requirements (including PHIPA and HIPAA), as well as with the best
        practices of health care providers. To keep your Personal Information secure, you are
        required to safeguard your subscriber name and password information in accordance with the
        Terms of Use.</span
      >
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c0"
        >In addition, we restrict unauthorized access through protective policies, procedures, and
        technical measures, including:</span
      >
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <ol class="c6 lst-kix_list_7-0 start" start="1">
      <li class="c2 c5 c11 li-bullet-0">
        <span class="c0"
          >providing reasonable physical and electronic safeguards with regard to the storage of
          Personal Information;</span
        >
      </li>
      <li class="c2 c5 c11 li-bullet-0">
        <span class="c0"
          >limiting access to your personal Information to those employees or contractors who we
          reasonably believe need to come into contact with that information to provide products or
          services to you or in order to do their jobs; and</span
        >
      </li>
      <li class="c2 c5 c11 li-bullet-0">
        <span class="c0"
          >governing employees and other contractors by strict standards and policies to ensure that
          Personal Information is secure and treated with the utmost care and respect.</span
        >
      </li>
    </ol>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c0"
        >Please note that no data transmission over the internet or otherwise can be guaranteed to
        be completely secure. As a result, while we strive to protect your Personal Information, we
        cannot warrant the security of any information you transmit to us, and you do so at your own
        risk.</span
      >
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c0"
        >If you have a security related concern, please contact us at the contact details provided
        above. We will work closely with you to ensure a quick and personal response to your
        concerns.</span
      >
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c4">15. </span><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span
      ><span class="c4">DISCLAIMER</span>
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c0"
        >IF YOU CHOOSE TO ACCESS THE PRODUCT AND SERVICES, YOU DO SO AT YOUR OWN RISK, AND ARE
        RESPONSIBLE FOR COMPLYING WITH ALL LOCAL LAWS, RULES AND REGULATIONS. WE MAY LIMIT THE
        AVAILABILITY OF THE PRODUCT AND SERVICES, IN WHOLE OR IN PART, TO ANY PERSON, GEOGRAPHIC
        AREA AND/OR JURISDICTION WE CHOOSE, AT ANY TIME AND IN OUR SOLE DISCRETION. OUR PRIVACY
        POLICY DOES NOT COVER THE INFORMATION PRACTICES OF OTHER COMPANIES AND ORGANIZATIONS WHO
        ADVERTISE OUR SERVICES, AND WHO MAY USE COOKIES, PIXEL TAGS, AND OTHER TECHNOLOGIES TO SERVE
        AND OFFER RELEVANT ADVERTISEMENTS.</span
      >
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c4">16. </span><span>&nbsp;</span><span class="c4">NOTIFICATIONS</span>
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c0"
        >We will never send email messages to customers requesting confidential information such as
        passwords, credit card numbers, or social security or social insurance numbers. Please do
        not act on any such emails as you may compromise your Personal Information by replying or by
        following links to a fraudulent website.</span
      >
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c4">17. </span><span>&nbsp;</span><span class="c4">MISCELLANEOUS</span>
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2">
      <span class="c0"
        >If any portion of this Privacy Policy is deemed unlawful, void or unenforceable by any
        arbitrator or court of competent jurisdiction, this Privacy Policy as a whole shall not be
        deemed unlawful, void or unenforceable, but only that portion of this Privacy Policy that is
        unlawful, void or unenforceable shall be stricken from this Privacy Policy. The insertions
        of headings are for convenient reference only and are not to affect the interpretation of
        this Privacy Policy.</span
      >
    </p>
    <p class="c2 c3"><span class="c0"></span></p>
  </div>
</template>
<style scoped>
ol {
  margin: 0;
  padding: 0;
}

table td,
table th {
  padding: 0;
}

.c1 {
  margin-left: 36pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 10pt;
}

.c0 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
}

.c2 {
  padding-top: 0pt;
  padding-bottom: 0pt;
}

.c8 {
  color: #000000;
  text-decoration: none;
  vertical-align: baseline;
}

.c9 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  orphans: 2;
  widows: 2;
  text-align: center;
}

.c13 {
  background-color: #ffffff;
}

.c10 {
  margin-left: 72pt;
  padding-left: 0pt;
}

.c6 {
  padding: 0;
  margin: 0;
}

.c4 {
  font-weight: 700;
}

.c7 {
  font-style: italic;
}

.c3 {
  height: 10pt;
}

.c5 {
  margin-left: 36pt;
}

.c11 {
  padding-left: 0pt;
}

.c12 {
  margin-left: 18pt;
}

.title {
  padding-top: 0pt;
  color: #000000;
  font-size: 28pt;
  padding-bottom: 0pt;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  font-family: 'Georgia';
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}

li {
  color: #000000;
  font-size: 10pt;
}

p {
  margin: 0;
  color: #000000;
  font-size: 10pt;
}

h1 {
  padding-top: 0pt;
  color: #2e74b5;
  font-size: 16pt;
}

h2 {
  padding-top: 0pt;
  color: #2e74b5;
  font-size: 13pt;
}

h3 {
  padding-top: 0pt;
  color: #1f4d78;
  font-size: 12pt;
}

h4 {
  padding-top: 0pt;
  color: #2e74b5;
  font-size: 10pt;
  font-style: italic;
}

h5 {
  padding-top: 0pt;
  color: #2e74b5;
  font-size: 10pt;
  padding-bottom: 0pt;
}

h6 {
  padding-top: 0pt;
  color: #1f4d78;
  font-size: 10pt;
  padding-bottom: 0pt;
}
</style>

<script>
export default {
  name: 'tos',
  data() {
    return {};
  },
};
</script>
