<template>
  <privacy-policy />
</template>
<script>
import PrivacyPolicy from '../components/PrivacyPolicy.vue';

export default {
  components: {
    PrivacyPolicy,
  },
};
</script>
