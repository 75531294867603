import moment from 'moment-timezone';

const en = {
  format: {
    prettyDateLong: (ctx) => {
      const date = ctx.named('date');
      const timezone = moment.tz.guess();
      return `${moment
        .unix(date)
        .tz(timezone)
        .format('dddd MMMM Do[,] YYYY')} at ${moment.unix(date).format('HH:mm A')}`;
    },
  },
  actions: {
    enterCode: 'Manually enter code',
    joinRoom: 'Join room',
  },
  cancel: {
    cancelError: 'Could not cancel appointment. Please try again.',
    confirm: 'Yes, I want to cancel.',
    confirmTitle: 'Are you sure you want to cancel your appointment?',
    cancelled: 'Your appointment has been cancelled.',
    errors: {
      tooClose:
        'Appointment cannot be cancelled as it is scheduled within the next 2 days. Please call us for assistance:',
    },
    exit: 'Click to exit site.',
  },
  helpBubble: {
    tooltip: 'Click to view our FAQ.',
  },
  login: {
    enterPassword: 'Please enter your password to access this area',
    termsOfService: {
      read: 'I have read and agree to the',
      termsOfService: 'Terms of Service',
    },
    privacyPolicy: 'Privacy Policy',
    submit: 'Submit',
  },
  end: {
    back: 'Rejoin Call',
    left: 'Disconnected from call.',
  },
  errors: {
    accessDenied: 'Access denied',
    linkExpired: 'Link Expired',
    incorrectPassword: 'The password entered is incorrect',
  },
  header: {
    back: 'Back to Settings',
  },
  kickedOut: {
    removed: 'You were removed from the meeting.',
  },
  options: {
    disableCam: 'Disable camera',
    muteMic: 'Mute microphone',
    permissionsError:
      'ERROR: The browser does not have access to your microphone and/or camera. Please enable them to continue.',
    permissionsLink:
      'https://support.google.com/chrome/answer/2693767?hl=en&co=GENIE.Platform%3DDesktop',
    permissionsLinkText: 'How to enable microphone and camera',
    stopTestMic: 'Stop testing microphone',
    testMic: 'Test microphone',
    next: 'Continue',
    videoDisabled: 'Video disabled',
  },
  password: {
    enterPassword: 'Please enter your secure password.',
    error: 'There was a problem accessing your secure meeting.',
  },
  pin: {
    enterPassword: 'Please enter your secure PIN.',
    error: 'There was a problem accessing your secure meeting.',
    expiredSession: 'Error: The appointment has expired.',
  },
  instructions: {
    accessAppointment:
      'To access your video appointment, please follow the meet link that was emailed to you.',
    bookAppointment: 'Please contact support to book another appointment.',
    completedTitle: 'The moderator has ended this meeting.',
    completedSubtitle: '',
    joinAnotherMeeting: 'Join Another Meeting',
    readyTitle: 'The doctor has joined the room',
    readySubtitle: 'You may now join the call.',
    removedInfo: 'The call has concluded or you were manually removed from it.',
    removedInstructions:
      'To re-join your video appointment,  please follow the meet link that was emailed to you.',
    standByTitle: 'Please stand by.',
    standBySubtitle: 'You will be automatically notified when the moderator opens up the room.',
  },
  sentry: {
    phoneSupport: '647-846-8089',
  },
  serverStatus: {
    maintenance: 'Maintenance',
    offline: 'Offline',
    online: 'Online',
    scheduledMaintenance: 'Server is undergoing scheduled maintenance until {endTime}.',
    serverOffline:
      'Server is experiencing unexpected downtime. Please be patient as our technicians get things back online.',
    serverOnline: 'Server is @.lower:serverStatus.online',
    upcomingMaintenance: 'Upcoming server maintenance',
  },
  start: {
    accept: 'Accept',
    decline: 'Decline',
    readTerms: 'Please read and accept the Terms of Service and Privacy Policy to continue.',
    readTermsOfService: 'Please read and accept the {tos} and {privacyPolicy} to continue.',
    welcome: `Welcome to your secure Sentry Health appointment.`,
  },
  waitingRoom: {
    eventScheduled: 'This event is scheduled for',
    doctorJoined: 'The doctor has joined the room',
  },
  util: {
    and: 'and',
  },
};

export default en;
