<template>
  <div class="text-center d-flex flex-column align-center justify-center">
    <div class="mt-3" v-if="!cancelled">
      <h2>
        {{ $t('cancel.confirmTitle') }}
      </h2>
      <div class="d-flex justify-center mt-10">
        <v-btn
          color="error"
          @click="cancel"
          class="d-flex justify-center"
          :style="{ width: '240px' }"
          :disabled="cancelling"
        >
          <v-progress-circular indeterminate v-if="cancelling" size="20" />
          <div v-else>{{ $t('cancel.confirm') }}</div>
        </v-btn>
      </div>
    </div>
    <div v-else>
      <h2>
        {{ $t('cancel.cancelled') }}
      </h2>
      <div class="d-flex justify-center mt-10">
        <v-btn color="primary" @click="leave">
          {{ $t('cancel.exit') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import to from 'await-to-js';
import CANCEL_MEETING from '../graphql/Mutation/PublicOTNAppointmentCancel.gql';

export default {
  name: 'CancelConfirm',
  data() {
    return {
      cancelled: false,
      cancelling: false,
    };
  },
  computed: {
    token() {
      return this.$store.state.authToken;
    },
  },
  methods: {
    async cancel() {
      this.cancelling = true;
      try {
        await this.cancelMeeting();

        this.cancelling = false;
        this.cancelled = true;
      } catch {
        this.cancelling = false;
      }
    },
    async cancelMeeting() {
      const [errors, query] = await to(
        this.$apollo.mutate({
          mutation: CANCEL_MEETING,
          variables: {
            facilitationToken: this.token,
          },
        })
      );
      if (errors) {
        const { text, linkText, linkUrl } = this.getErrorMessage(errors);
        this.$store.dispatch('setNotification', {
          color: 'error',
          text: text || this.$t('cancel.cancelError'),
          timeout: text ? -1 : 8000,
          linkText,
          linkUrl,
        });
        throw new Error(errors);
      }
      return query;
    },
    getErrorMessage(errors = {}) {
      const { message } = errors;

      const isTooClose = new RegExp('too close to its scheduled time to cancel');
      if (isTooClose.test(message))
        return {
          text: this.$t('cancel.errors.tooClose'),
          linkText: '1-705-328-2019',
          linkUrl: 'tel:+17053282019',
        };
      return {};
    },
    leave() {
      window.location.href = 'https://www.sentryhealth.ca/';
    },
  },
};
</script>
