<template>
  <div class="text-center d-flex flex-column align-center justify-center">
    <div class="mt-3">
      <h2>
        {{ $t('pin.enterPassword') }}
      </h2>
      <div class="enter-password-container d-flex justify-center mt-8">
        <div class="enter-password">
          <v-text-field
            outlined
            width="100"
            v-model="submittedPin"
            :error-messages="errors"
            class="password-input"
            @keydown="onKeydown"
            :loading="loading"
          >
          </v-text-field>
        </div>
      </div>
      <div class="mt-3">
        <v-btn color="primary" :disabled="isDisabled" @click="onSubmit">{{
          $t('login.submit')
        }}</v-btn>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.enter-password {
  width: 200px;
  .v-text-field__slot {
    input {
      text-align: center;
      font-size: 2em !important;
    }
  }
}
.enter-password-container {
  width: 100%;
}
.password-input {
  input {
    text-transform: uppercase;
  }
}
</style>
<script>
import to from 'await-to-js';
import moment from 'moment';
import PUBLIC_OTN_TOKEN from '../graphql/Query/PublicOTNTokenExchange.gql';
import { AUTH_TOKEN } from '../constants/storage';
import { getErrorMessage } from '../utils/errors';
import { parseJwt } from '../utils/validation';

export default {
  name: 'Pin',
  data() {
    return {
      loading: false,
      submittedPin: null,
      errors: [],
    };
  },
  computed: {
    isDisabled() {
      return this.submittedPin?.length !== 6;
    },
    params() {
      const { appointmentId, providerId, facilitationToken } = this.$store.state;
      return {
        appointmentId,
        providerId,
        facilitationToken,
      };
    },
  },
  methods: {
    isTokenExpired(token) {
      const parsed = parseJwt(token);
      const { exp } = parsed;
      const now = moment().unix();
      return now > exp;
    },
    onKeydown(e) {
      if (e.code !== 'Enter') {
        return;
      }
      if (this.isDisabled) {
        return;
      }
      this.onSubmit();
    },
    async onSubmit() {
      this.loading = true;
      this.errors = [];

      // format for backend: xxxxxx
      const { facilitationToken } = this.params;
      const { submittedPin } = this;

      const [errors, query] = await to(
        this.$apollo.query({
          query: PUBLIC_OTN_TOKEN,
          variables: {
            facilitationToken,
            submittedPin,
          },
          fetchPolicy: 'no-cache',
        })
      );

      if (errors) {
        const readableError = getErrorMessage(errors);
        if (readableError) {
          this.errors.push(readableError);
        } else {
          this.errors.push(this.$t('password.error'));
        }
      } else {
        const authToken = query.data.publicOTNTokenExchange.meetToken;
        if (this.isTokenExpired(authToken)) {
          this.errors.push(this.$t('pin.expiredSession'));
          this.loading = false;
          return;
        }
        this.$store.commit('set', {
          authToken,
        });
        localStorage.setItem(AUTH_TOKEN, this.token);
        this.$router.push({ name: 'Start' });
      }
      this.loading = false;
    },
  },
  mounted() {
    this.$store.commit('set', {
      loading: false,
      meetContainer: { height: '450px', width: '650px' },
    });
  },
};
</script>
