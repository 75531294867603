<template>
  <div class="language-select__container pa-2" :style="style">
    <v-select
      :items="items"
      dense
      hide-details
      v-model="lang"
      @change="$emit('change', $event)"
      :menu-props="{ contentClass: 'language-select__menu' }"
    >
      <template slot="prepend-inner">
        <v-icon>mdi-web</v-icon>
      </template>
      <template v-slot:item="{ item }">
        <div style="width: 100%; height: 100%">{{ item.text }}</div>
      </template>
    </v-select>
  </div>
</template>
<style lang="scss">
.language-select__container {
  position: absolute;
  top: 0;
  max-width: 150px;
  z-index: 1;
}
</style>
<script>
import locales from '../constants/locales';
import { LANG } from '../constants/storage';

export default {
  name: 'LanguageSelect',
  data() {
    return {
      items: locales,
      lang: 'en',
    };
  },
  created() {
    const lang = localStorage.getItem(LANG);
    this.lang = lang || 'en';
  },
  computed: {
    route() {
      return this.$route.name;
    },
    isCall() {
      return this.route === 'Call';
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    style() {
      const rightPos = this.isCall && this.isMobile ? '50px' : '0';
      return `right: ${rightPos}`;
    },
  },
};
</script>
