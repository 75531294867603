// eslint-disable-next-line import/prefer-default-export
export const PUBLIC_PATHS = [
  'CancelGateway',
  'Expired',
  'Gateway',
  'NoAccess',
  'Password',
  'Pin',
  'PrivacyPolicy',
  'TermsOfService',
];
