<template>
  <div class="server-status-container pa-2 d-flex align-center">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          v-bind="attrs"
          v-on="on"
          :color="serverOnline ? 'success' : serverMaintenance ? 'warning' : 'error'"
          >{{
            serverOnline
              ? $t('serverStatus.online')
              : serverMaintenance
              ? $t('serverStatus.maintenance')
              : $t('serverStatus.offline')
          }}</v-chip
        >
      </template>
      <span> {{ infoText }}</span>
    </v-tooltip>
    <span
      v-if="isUpcomingMaintenance"
      class="error--text ml-2 d-flex flex-column maintenance-warning"
      ><div>{{ $t('serverStatus.upcomingMaintenance') }}:</div>
      <div>{{ upcomingMaintenanceTime }}</div></span
    >
  </div>
</template>
<style scoped>
.maintenance-warning {
  font-size: 0.9em !important;
}
.server-status-container {
  position: absolute;
  left: 0;
  top: 0;
}
</style>
<script>
import to from 'await-to-js';
import moment from 'moment-timezone';
import GET_STATUS from '../graphql/Query/GetStatus.gql';
import { PRETTY_DATE_TIME_FORMAT } from '../constants/moment';

export default {
  name: 'ServerStatus',
  data() {
    return {
      isActive: true,
      maintenanceStart: 0,
      maintenanceEnd: 0,
      updatedAt: null,
    };
  },
  computed: {
    infoText() {
      if (this.serverOnline) {
        return this.$t('serverStatus.serverOnline');
      }
      if (this.serverMaintenance) {
        return this.$t('serverStatus.scheduledMaintenance', {
          endTime: `${this.maintenanceEndTime} ${this.userTimezoneAbbr}`,
        });
      }
      return this.$t('serverStatus.serverOffline');
    },
    isUpcomingMaintenance() {
      if (this.serverMaintenance || this.maintenanceStart === 0 || this.maintenanceEnd === 0)
        return false;

      const now = moment().unix();
      return !this.maintenanceStart < now && this.maintenanceEnd > now;
    },
    maintenanceEndTime() {
      return moment
        .unix(this.maintenanceEnd)
        .tz(this.userTimezone)
        .format(PRETTY_DATE_TIME_FORMAT);
    },
    now() {
      return moment().unix();
    },
    serverOnline() {
      return this.isActive;
    },
    serverMaintenance() {
      const now = moment().unix();
      return this.maintenanceStart <= now && this.maintenanceEnd > now;
    },
    upcomingMaintenanceTime() {
      const start = moment
        .unix(this.maintenanceStart)
        .tz(this.userTimezone)
        .format(PRETTY_DATE_TIME_FORMAT);
      const end = moment
        .unix(this.maintenanceEnd)
        .tz(this.userTimezone)
        .format(PRETTY_DATE_TIME_FORMAT);
      return `${start} ${this.userTimezoneAbbr} - ${end} ${this.userTimezoneAbbr}`;
    },
    userTimezoneAbbr() {
      return moment.tz(this.userTimezone).zoneAbbr();
    },
    userTimezone() {
      return moment.tz.guess();
    },
  },
  methods: {
    async getStatus() {
      const [errors, query] = await to(
        this.$apollo.query({
          query: GET_STATUS,
        })
      );
      if (!errors) {
        const { isActive, maintenanceStart, maintenanceEnd, updatedAt } = query.data.getStatus;
        this.isActive = isActive;
        this.maintenanceStart = Number(maintenanceStart);
        this.maintenanceEnd = Number(maintenanceEnd);
        this.updatedAt = Number(updatedAt);
      }
    },
  },
  mounted() {
    this.getStatus();
    /* will check server status every 10 minutes */
    setInterval(() => {
      this.getStatus();
    }, 600000);
  },
};
</script>
