<template>
  <div class="meeting-ended-container d-flex align-center flex-column mt-7">
    <div>
      <h2>{{ $t('instructions.completedTitle') }}</h2>
    </div>
    <div class="mt-10">
      <v-btn color="primary" @click="leave">{{ $t('instructions.joinAnotherMeeting') }}</v-btn>
    </div>
  </div>
</template>
<style scoped>
.meeting-ended-container {
  height: 100%;
}
</style>
<script>
export default {
  name: 'CallOver',

  data() {
    return {
      meeting: true,
    };
  },
  methods: {
    leave() {
      this.$router.push({ name: 'Password' });
    },
  },
  mounted() {
    this.$store.commit('set', {
      meetContainer: { height: '450px', width: '650px' },
    });
    this.$store.dispatch('resetSession');
  },
};
</script>
