<template>
  <div class="loading-container d-flex justify-center align-center">
    <v-progress-circular indeterminate size="50" color="white"></v-progress-circular>
  </div>
</template>
<style lang="scss">
.loading-container {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  margin-top: -48px;
  z-index: 10;
  border-radius: 4px;
}
</style>
<script>
export default {
  name: 'Loading',
  data() {
    return {};
  },
};
</script>
