<!-- eslint-disable max-len  -->
<template>
  <div class="tos">
    <p style="text-align: center">
      <strong>PRODUCT LICENSE AGREEMENT AND END USER LICENSE AGREEMENT</strong>
    </p>
    <p>
      The following summary (&ldquo;<strong>Summary of Terms</strong>&rdquo;) is subject to the
      terms and conditions set out in the schedules to this agreement (and together, this Summary of
      Terms and all schedules are the &ldquo;<strong>Agreement</strong>&rdquo;).
    </p>
    <p>
      <strong
        >If any End-User does not agree to this Agreement, such End-User shall agree to not use the
        Product (defined below in Schedule A) and in doing so shall not be granted a license to the
        Product. See &ldquo;Acceptance of the Terms&rdquo;, below.</strong
      >
    </p>
    <p>
      <strong>Last Modified Date:</strong
      ><strong
        ><em>{{ LAST_MODIFIED_DATE }}</em></strong
      >
    </p>
    <p>
      <strong>Effective Date:</strong
      ><strong
        ><em>{{ EFFECTIVE_DATE }}</em></strong
      >
    </p>
    <p><strong>Parties</strong></p>
    <p>This Agreement is between:</p>
    <p>
      <strong>1.</strong> Sentry Health Inc. a company incorporated under the Federal laws of Canada
      (the &ldquo;<strong>Company</strong>&rdquo;), and
    </p>
    <p>
      <strong>2.</strong> The &ldquo;<strong>Client</strong>&rdquo; or
      &ldquo;<strong>End-User</strong>&rdquo; who has registered for the use of the Product, and
      together with the Company, each a &ldquo;<strong>Party&rdquo;</strong>, and collectively the
      &ldquo;<strong>Parties</strong>&rdquo;).
    </p>
    <p><strong>Acceptance of The Agreement in Order to Use the Site.</strong></p>
    <p>
      It is important that each End-User read this Agreement carefully. By registering for, logging
      in and/or otherwise accessing or using the Product which each End-User agrees to be good and
      valuable consideration, such End-User hereby represents, warrants and signifies that such
      End-User is: (a) at least 18 years of age; (b) has read, understood and agrees to be bound by
      this Agreement as it may be amended from time to time; and (c) has read and understood the
      Company Privacy Policy, which can be accessed at Sentryhealth.ca/privacy-policy, the "<strong
        >Privacy Policy</strong
      >"), the terms of which are incorporated herein by reference, and agrees to abide by the
      Privacy Policy.
    </p>
    <p>
      Client and/or End-User further agree to abide by all the terms in theGoogle Play Store and the
      Apple App Store (each a "<strong>Distribution Platform</strong>") end user licensing
      agreement, or any other applicable Distribution Platform agreement, so long as such agreement
      does not conflict with the terms herein.
    </p>
    <p><strong>DISCLAIMER</strong></p>
    <p>
      SENTRY HEALTH INC. IS NOT PROVIDING ANY MEDICAL ADVICE OF ANY KIND. CLIENT AND/OR END-USER USE
      OF THE PRODUCT ARE AT CLIENT'S OWN RISK. INFORMATION REGARDING HEALTH, MEDICATIONS OR ANY
      MEDICAL ADVICE ARE PROVIDED BY THIRD PARTIES. SENTRY HEALTH INC. DOES NOT REPRESENT, WARRANT
      OR ACCEPT ANY LIABILITY WITH RESPECT TO ANY AND ALL CONTENT, ADVICE, NOTIFICATIONS OR OTHER
      INFORMATION RECEIVED THROUGH THE USE OF THE PRODUCT.
    </p>
    <p>
      Any information received through use of the Product that is not part of&nbsp;an authorized
      session with your healthcare professional is purely informational and does not amount to
      medical advice. Do not use any information received through the use of the Product to diagnose
      or treat a medical condition or health concern.
    </p>
    <p><strong>No Substitute for Professional Advice.&nbsp;</strong></p>
    <p>
      The Company Product is not intended to be a substitute for professional advice. Under no
      circumstances will we be liable for any loss or damage caused by your reliance on information
      obtained through the Company Product. It is your responsibility to evaluate the accuracy,
      completeness or usefulness of any information, opinion, advice or other content available
      through the Company Product. Please seek the advice of professionals, as appropriate,
      regarding the evaluation of any specific information, opinion, advice or other content. Never
      disregard professional advice, including medical advice, or delay in seeking it, because of
      something you have read on the Company Product.&nbsp;
    </p>
    <p><strong>Summary of License Terms.&nbsp;</strong></p>
    <p>
      During the Initial Term or subsequent Renewal Term (as defined below), the Company grants to
      the End-User a non-exclusive, non-transferable, non-sub licensable, revocable licenseto the
      Product, the features of which are described in Schedule &ldquo;B&rdquo; to this Agreement.
    </p>
    <p>
      The license can be revoked for breach of the Agreement and/or for any of the Prohibited Uses
      as defined below in Schedule &ldquo;C&rdquo; to this Agreement, examples of which are
      submission to the Company or any other End-Users of objectionable content, and/or acts of
      abuse to the Company or to any other End-Users, and/or of any Prohibited Use of the product,
      among others).
    </p>
    <p>
      Complete license terms are contained in Schedule &ldquo;A&rdquo; to this Agreement.
    </p>
    <p><strong>Fees and Timeline</strong></p>

    <p><strong>Term</strong></p>
    <p>
      This Agreement shall apply to each Client and/or End-User commencing from the time that such
      End-User first registers to use the Product (the &ldquo;<strong>Effective Date</strong
      >&rdquo;) and, subject to the clauses that survive this agreement, until the termination of
      the Agreement at the conclusion of a one-year period (the &ldquo;<strong>Initial Term</strong
      >&rdquo;). The Agreement will renew automatically on a year-to-year basis thereafter (each a
      &ldquo;<strong>Renewal Term</strong>&rdquo;) until such time as it is terminated in accordance
      with the termination provisions of this Agreement.
    </p>
    <p><strong>Data Usage</strong></p>
    <p>
      The Client acknowledges that, and the End-User acknowledges that, to aid in supporting the
      Company business goals and in improving the End-User experience, the Company processes
      End-User User Data through third party storage, hosting and data processing services that are
      comparable to the processing services provided by similar companies to the Client. A complete
      list of third-party data processing providers can be found within the Privacy Policy to this
      Agreement, which can be accessed at sentryhealth.ca/privacy-policy.
    </p>
    <p><strong>Support</strong></p>
    <p>
      For support inquiries, or to report objectionable content, please contact the Company at:
      victor@sentryhealth.ca
    </p>
    <p style="text-align: center">
      <em>[the remainder of this page is intentionally left blank]</em>
    </p>
    <p style="text-align: center"><strong>Schedule &ldquo;A&rdquo;</strong></p>
    <p style="text-align: center">
      <strong>Terms and Conditions to the Product Licence Agreement</strong>
    </p>
    <p>
      <strong>1. PARTIES ACCESSING THE SOFTWARE AGREE TO BE BOUND BY OUR TERMS</strong>
    </p>
    <p>
      This Agreement will govern the Client and End-User access of the Sentry Health products,
      including but not limited to the Sentryhealth.ca<strong><em>,</em></strong>
      whether accessed: (a) on a computer connected to the internet at Sentryhealth.ca (the
      &ldquo;<strong>Website</strong>&rdquo;); (b) on the Company social media properties including
      Facebook, Linkedin, Instagram, Youtube and any other social platforms that the Company may use
      from time to time; and/or (c) by downloaded on a mobile hardware device (individually and
      collectively, (a), (b), and (c) are the &ldquo;<strong>Product</strong>&rdquo;), as owned and
      operated by the Company.
    </p>
    <p>
      No End-User may use the Product or accept this Agreement if such End-User is not of a legal
      age to form a binding contract with us. In accepting this Agreement, each End-User represents
      that such End-User has the capacity to be bound by them, and/or if such End-User is acting on
      behalf of the Client, that such End-User has the authority to bind such company or entity (and
      in which case Client will refer to the company or entity).
    </p>
    <p>
      It is important that you read these Terms carefully. If you do not agree to these Terms,
      please do not use the Product.&nbsp;By accessing or using the Product, you represent, warrant
      and signify that:&nbsp;
    </p>
    <ol>
      <li>
        if you are a health professional or therapist ("<strong>Health Care Provider</strong>"), you
        are currently compliant, and agree to remain in compliance in the future, with all laws
        applicable to you, including (as applicable) the
        <em>Personal Health Information Protection Act</em>, 2004, SO 2004, c 3, Sch A
        (&ldquo;<strong>PHIPA</strong>&rdquo;), the
        <em>Health Insurance Portability and Accountability Act of 1996 </em
        >(&ldquo;<strong>HIPAA</strong>&rdquo;) etc., all as amended from time to time, including
        any regulations thereto (individually and collectively shall be included in the definition
        of &ldquo;<strong>Applicable Laws</strong>&rdquo;);
      </li>
      <li>
        that you have read, understood and agree to be bound by these Terms as they may be amended
        from time to time;
      </li>
      <li>
        if you are a Health Care Provider you have obtained any applicable consents required from
        your patients&rsquo; relating to the dissemination of personal health information and your
        potential use of third-party service providers, such as Sentry Health; and
      </li>
      <li>
        (d)&nbsp;you have read and understand our Privacy Policy at Sentryhealth.ca/privacy-policy,
        the terms of which are incorporated herein by reference (the "<strong>Privacy Policy</strong
        >"), and agree to abide by the Privacy Policy.
      </li>
    </ol>
    <p>
      You may not use the Product or Services nor accept these Terms if you are not of a legal age
      to form a binding contract with us. If you accept these Terms, you represent that you have the
      capacity to be bound by them, or if you are acting on behalf of a company or entity, that you
      have the authority to bind such company or entity (and in which case
      &ldquo;<strong>you</strong>&rdquo; and &ldquo;<strong>your</strong>&rdquo; will refer to the
      company or entity).
    </p>
    <p><strong>2. AMENDMENT</strong></p>
    <p>
      Pursuant to the amendment restrictions set out in Applicable Law (defined below), the Company
      may add to, discontinue or revise this Agreement or any aspect, mode, design, or service
      provided under the Product which include but are not limited to the:
    </p>
    <ol>
      <li>scope of the features;</li>
    </ol>
    <ol>
      <li>timing of the features;</li>
    </ol>
    <ol>
      <li>software/hardware required for access to the Product; and</li>
    </ol>
    <ol>
      <li>
        geographic locations or jurisdictions in which certain features may be available.
      </li>
    </ol>
    <p>
      The Company may amend this Agreement without notice for non-material amendments. In the event
      of a material change during the Initial Term or prior to a Renewal Term, we will provide the
      End-User with thirty (30) days&rsquo; notice (or the minimum notice period as required under
      Applicable Law, whichever is greater) of any such change (including changes in pricing) via
      e-mail to the e-mail address supplied to the Company by the Client and/or End-User, setting
      out:
    </p>
    <ol>
      <li>the new or amended agreement terms;</li>
    </ol>
    <ol>
      <li>how such terms read formerly;</li>
    </ol>
    <ol>
      <li>the date of the coming into force of the amendment;</li>
    </ol>
    <ol>
      <li>
        the means in which to respond to the notice of amendment and the effects of not responding;
      </li>
    </ol>
    <ol>
      <li>
        the option to either terminate the agreement or retain the existing agreement unchanged; and
      </li>
    </ol>
    <ol>
      <li>
        the language of this provision with reference to the applicable consumer protection
        legislation rules for amending this Agreement and making any additional requirements for
        amendments as prescribed by law (if any).
      </li>
    </ol>
    <p>
      It is the Client and/or End-User&rsquo;s responsibility to send such notices of material
      changes to all End-Users associated with the End-User&rsquo;s account. The Company highly
      recommends that each Client and/or End-User read any amendments carefully. Unless explicit
      consent is required by the law, the Company has the right to assume that each Client and/or
      End-User accessing the Product through the Product registration has accepted the change to
      this Agreement, unless such Client and/or End-User notifies the Company to the contrary, no
      later than thirty (30) days after the amendment comes into force (or the minimum number of
      days as required under Applicable Law, whichever is greater), that such Client and/or End-User
      desires to cancel the contract or deregister or unsubscribe from access to the Product.
    </p>
    <p>
      The Company will post the most current terms to this Agreement conspicuously within the
      Product and the Client and/or End-User&rsquo;s use of the Product will be subject to the most
      current terms as posted on the Product at such time. It is the Client and/or End-User&rsquo;s
      responsibility to visit this page to find any updates that may have been made to the
      Agreement. Each Client and/or End-User hereby agrees that the Company shall not be liable to
      such End-User for any amendments to the Agreement.
    </p>
    <p><strong>3. PAYMENT TERMS</strong></p>
    <p>
      All payments shall be made and managed through a third-party payment service provider or
      Distribution Platform utilized by&nbsp;the Company. We will notify the Client and/or End-User
      of any price changes in accordance with the Amendment provision set out below (<strong
        >"Payment Method</strong
      >"). The Product may be available for use free of charge if a free trial period is offered to
      the Client and/or End-User at the Company's discretion and upon written agreement. In the
      event a free trial period is offered, the Client and/or End-User shall be required to provide
      a Payment Method and will not be charged unless otherwise set out in section 4 (Suspension and
      Termination). Payment from Client and/or End-Users will be due upon registration or delivery
      of an electronic invoice from the Company to the Client and/or End-User, as applicable, and
      such payment shall be processed through a third-party payment service provider subject to the
      terms herein. If there are any paid components of the Product, any amounts payable by the
      Client and/or End-User hereunder which remain unpaid thirty (30)days after an invoice is
      delivered shall bear interest at the rate of one (1%)percent per month (up to a maximum of
      twelve (12%) percent per annum, or the maximum amount allowable by law, such interest to be
      calculated on a daily basis from the date that the payment first becomes overdue until the
      date payment is made in full. It is solely the Client's and/or End-User's responsibility to
      ensure and determine the eligibility of any amounts payable with their insurance company.
      Certain insurance companies offer direct billing. The Client and/or End-User may submit
      insurance claims directly to their insurance company or provide the Company with direct
      billing information for their insurance company if the company offers direct billing. It is
      the Client's and/or End-User's responsibility to ensure that such direct billing information
      is accurate and up to date. In the event that the Client and/or End-User debit or credit card
      information cannot be processed on the date of payment due, the Company may at the
      Company&rsquo;s discretion, periodically attempt to process payment.
    </p>
    <p>
      All payment is exclusive of any taxes or duties imposed by applicable tax laws in each
      Client&rsquo;s and/or End-User&rsquo;s tax jurisdictions, and the Company&nbsp;will not be
      responsible for any taxes or duties owed by the Client and/or End-User.
    </p>
    <p><strong>4. SUSPENSION AND TERMINATION</strong></p>
    <p>
      Client and/or End-Users may terminate their relationship with the Company by notifying the
      Company via email at
      <strong
        ><em>{{ CONTACT_EMAIL }}</em></strong
      >&nbsp; The Client acknowledges that more than one End-User may be registered on behalf of the
      Client who are not responsible for payment, and therefore that services provided by the
      Product and the applicable fees shall continue until the Client has notified the Company of
      the Client&rsquo;s desire to unsubscribe from the Product, to close all End-User accounts
      associated with the Client, and to cease the Client and End-User&rsquo;s use of the Product.
    </p>

    <p>
      The Company may suspend provision of the Product in the event that the Client and/or End-User
      fails to make any payment when due hereunder, the Client ceases to carry on its business in
      the normal course, or if an event of Prohibited Use (as described in Schedule &ldquo;C&rdquo;
      to this Agreement) occurs, or if Client or End-User is in material breach of the Agreement.
      Suspension shall have no effect on the payment obligations of the Client and/or End-User
      during the Agreement Term or Renewal Term. A suspension event shall be included in the
      definition of Client and/or End-User material breach. The Client and/or End-User agrees to pay
      the Company&rsquo;s reasonable expenses, including lawyer and collection agency fees, incurred
      in enforcing the Company&rsquo;s right to payment.
    </p>
    <p>
      At the Company&rsquo;s discretion, the Company may terminate this Agreement immediately at any
      time and for any reason including, but not limited to:
    </p>
    <p>
      a) an End-User or Client material breach of this Agreement, including failure to make payments
      when due;
    </p>
    <p>
      b) in the event that amounts payable by the End-User remain unpaid thirty (30) days after an
      invoice is delivered, the Company may terminate the End-User&rsquo;s subscription to the
      Product;
    </p>
    <p>
      c) if the Client and/or End-User has not adhered to any or all the provisions of the Agreement
      (such as a failure to pay fees when due) or if it appears that the Client and/or End-User does
      not intend to or is unable to comply with the Terms, such determination to be made solely at
      the Company&rsquo;s discretion;
    </p>

    <p>d) if the Company is required to terminate the relationship by law;</p>
    <p>
      e) if the Company receives any notice of or discovers any event of Prohibited Use;
    </p>
    <p>
      f) if provision of the Product is no longer commercially viable for the Company; and/or
    </p>
    <p>
      g) if the Company has changed the Company&rsquo;s Agreement or Privacy Policy (Found at
      sentryhealth.ca/privacy-policy) and has not received the Client and/or End-User&rsquo;s
      required consent, pursuant to the amendment provision in this Agreement.
    </p>

    <p><strong>Warranty Period and Termination:&nbsp;</strong></p>
    <p>
      A Limited Warranty period may be provided to the Client and/or End-User, as described in the
      Summary of Terms. During this Limited Warranty period, the End-User may terminate this
      Agreement upon written notice within the Limited Warranty period for any reason. During the
      Limited Warranty period the Client and/or End-User will receive a refund for any amounts paid.
      No refunds will be provided for early termination outside of the Limited Warranty period, and
      the End-User will pay the Fees and Expenses in full for the Product provided by the Company up
      to and including the last day of the month following the termination date.
    </p>
    <p><strong>5. USE OF THE PRODUCT</strong></p>
    <p>
      In order to use the Product, the Client and/or End-User must register using the
      Company&rsquo;s registration page located within the app or on the Company's website. The
      End-User understands and agrees that a representative of the Client&rsquo;s organization may
      register the End-User for an account.
    </p>
    <p><strong>Registration Information</strong></p>
    <p>
      The End-User agrees and understands that the End-User is responsible for maintaining the
      confidentiality of the End-User&rsquo;s password, which, together with the End-User&rsquo;s
      name and e-mail address(&ldquo;<strong>User ID</strong>&rdquo;), allows the End-User to access
      the Product. The End-User ID and password, together with any other contact information the
      End-User provides the Company at the time of signing up for the Product comprise the
      End-User&rsquo;s &ldquo;<strong>Registration Information</strong>.&rdquo; The End-User agrees
      that all Registration Information provided to the Company will be accurate and up-to-date. The
      End-User agrees to keep the End-User&rsquo;s password secure. The Company will not be liable
      if the Company is unable to retrieve or reset a lost password. If the End-User becomes aware
      of any unauthorized use of the End-User&rsquo;s password or account, the End-User agrees to
      notify the Company via e-mail at "<strong
        ><em>{{ CONTACT_EMAIL }}</em></strong
      >
      " as soon as possible.
    </p>
    <p>
      The Client and/or End-user may not open an account if the Client and/or End-User is a
      competitor of the Company, as determined in the Company's discretion. The Company reserves the
      right to terminate without notice any accounts it determines, in its sole discretion, to be
      associated with a competitor and may take legal action against the competitor company.
    </p>
    <p><strong>Permitted Uses</strong></p>
    <p>
      The Client and/or End-User agrees to use the Product only for purposes that are permitted,
      both by the Agreement and by any applicable law, regulation, or generally accepted practices
      or guidelines, in relevant local, national, and international jurisdictions. The Client and/or
      End-User agrees to adhere to any applicable privacy of personal information laws and
      regulations.
    </p>
    <p><strong>Unauthorized Access</strong></p>
    <p>
      The Client and/or End-User agrees to only access (or try to access) and use the Product
      through interfaces provided by us. The Client and/or End-User shall not access (or try to
      access) and use the Product through any automated means, including, but not limited to,
      scrapers, scripts, robots, or web crawlers. The Client and/or End-User agrees not to use or
      attempt to use another End-User&rsquo;s account. The Client and/or End-User agrees not to
      impersonate any person or entity, or falsely state or otherwise misrepresent the Client and/or
      End-User, the Client and/or End-User&rsquo;s personal information, or the Client and/or
      End-User&rsquo;s affiliations with any person or entity.
    </p>
    <p><strong>Moderation of content created by the End-User</strong></p>
    <p>
      The Client and/or End-User understands and agrees that although the Companyis not required to
      moderate the End-User&rsquo;s use of the Product, it may in its sole judgment review and
      delete any content in whole or in part, for any reason whatsoever, which without limitation,
      violate this Agreement or which might be objectionable, offensive, indecent, illegal, or that
      might violate the rights, harm, or threaten the safety of others.
    </p>
    <p><strong>Objectionable content created by others</strong></p>
    <p>
      The Client and/or End-User understands that when using the Product, the End-User may come
      across material that the End-User finds objectionable, offensive or indecent and agree that
      the End-User is using the Product at the End-User&rsquo;s own risk. The End-User may alert the
      Company by using the support contact information contained herein.
    </p>
    <p><strong>User Responsibility</strong></p>
    <p>
      The Client and/or End-User agrees that the Client and/or End-User is solely responsible for
      any breach of the End-User&rsquo;s obligations under the Agreement and for the consequences of
      any such breach. The Company has no responsibility to the Client and/or End-User or to any
      third party for such breaches or the consequences of such breaches (including losses or damage
      that we may incur).
    </p>
    <p><strong>Technical Requirements</strong></p>
    <p>
      Use of the Product requires internet access through the End-User&rsquo;s device. The End-User
      may be required to have the most up-to-date iOS operating system to use the Product, and some
      features of the Product may not be accessible with such technologies disabled.
    </p>
    <p><strong>User Responsibility for Equipment</strong></p>
    <p>
      The End-User agrees to be responsible for obtaining and maintaining any software, browsers,
      hosting services, other equipment and ancillary services needed to connect to, access or
      otherwise use the Product.
    </p>
    <p><strong>6. PRIVACY</strong></p>
    <p>
      The End-User&rsquo;s privacy is very important to us. Please review the Company&rsquo;s
      Privacy Policy at sentryhealth.ca/privacy-policy. Our Privacy Policy applies to the
      collection, use, disclosure, retention, protection and accuracy of the End-User&rsquo;s
      personal information collected for the purposes of the features offered through the Product.
    </p>
    <p><strong>7. THIRD PARTY LINKS</strong></p>
    <p>
      The Product may link to third-party websites and/or resources. Such links are provided as a
      convenience to the End-User only and do not imply an endorsement, warranty or guarantee by the
      Company of any such linked website or the company it purports to represent. The Company does
      not assume any responsibility or liability for the availability or accuracy of such links,
      and/or the content products or services provided at the destinations of such links. The Client
      and/or End-User is solely responsible for use of any such websites or resources and compliance
      with their policies. Should the End-User elect to enter into a binding contract with any such
      third party website and/or resource, the Client and/or End-User agrees to hold the Company
      harmless and hereby release the Company from any liability whatsoever, whether arising out of
      contract, tort or otherwise, for any liability, claim, injury, loss or damage suffered as a
      result of the Client and/or End-User&rsquo;s actions or the actions of any Client and/or
      End-User associated with the Client and/or End-User&rsquo;s account, offering to accept or
      having accepted any products or services that are available from those sites.
    </p>
    <p><strong>8. INTELLECTUAL PROPERTY AND RIGHTS</strong></p>
    <p><strong>Rights to content provided by us</strong></p>
    <p>
      The Client and/or End-User acknowledges and understands that we own all right, title and
      interest in: (a) the Product and all improvements, enhancements or modifications thereto; (b)
      the Product and any associated data files; and (c) all computer software; advertisements;
      sponsored content; and intellectual property associated with the Product (all such
      information, individually and collectively, being the &ldquo;<strong>Product Content</strong
      >&rdquo;), which the Client and/or End-User may have access to when using the Product.
    </p>
    <p><strong>Know-How</strong></p>
    <p>
      Subject to the provisions hereof respecting confidentiality and intellectual property, the
      Company shall be free to use any ideas, concepts or know-how developed or acquired by the
      Company during the provision of the Product under this Agreement to the extent obtained and
      retained by the Company&rsquo;s personnel as impressions and general learning (the
      &ldquo;<strong>Know-How</strong>&rdquo;). Nothing in this Agreement shall be construed to
      preclude the Company from enhancing the Product based on the accumulation of Know-How.
    </p>
    <p>
      The Client and/or End-User is not required to provide the Company with any comments,
      suggestions, recommendations, bug reports, requests or any other feedback
      (&ldquo;<strong>Feedback</strong>&rdquo;). In the event that the Client and/or End-User do
      provide the Company with Feedback, the Company may use such feedback to improve the Product or
      for any other purpose. Furthermore, the Company shall own such Feedback and the Company and
      its affiliates, licensees, clients, partners, third-party providers and other authorized
      entitled may use, license, distribute, reproduce and commercialize the Feedback, and the
      Client and/or End-User hereby assigns, irrevocably, exclusively and on a royalty-free basis,
      all such Feedback to the Company.
    </p>
    <p><strong>Limited license</strong></p>
    <p>
      &nbsp;The Company hereby grants the Client and/or End-User a non-exclusive, non-transferable,
      revocable, limited license to use the Product in accordance with this Agreement and the
      License Restrictions set out in the Summary of Terms<strong><em>.</em></strong> This limited
      license is subject to full payment of the Fees when due. This license may be revoked by the
      Company upon breach of this Agreement by the Client and/or End-User and shall automatically be
      revoked upon termination or expiration of this Agreement.
    </p>
    <p>
      The Company may, now or in the future, own rights to trade-marks, trade names, services marks,
      logos, domain names and other distinctive brand features which we use in connection with the
      operation of the Product (each such feature being a &ldquo;<strong>Brand Right</strong>&rdquo;
      and collectively being the &ldquo;<strong>Brand Rights</strong>&rdquo;). The Company does not
      grant the Client and/or End-User any right or license to use any Brand Right other than as
      expressly set out in this Agreement and in other licenses between the Client and/or End-User
      and the Company.
    </p>
    <p><strong>Licence to Brand Marks</strong></p>
    <p>
      The Company and Client and/or End-User agree and understand to grant to each other a
      perpetual, non-exclusive, royalty-free licence to use one another&rsquo;s name, logos, and/or
      trademark (individually and collectively described as the
      &ldquo;<strong>brand</strong>&rdquo;) for the purposes of any press release, advertising,
      webpage, blog or other promotional, advertising or marketing material so long as no
      confidential information is disclosed, and such license shall be revocable upon written notice
      provided in the other in the brand owner&rsquo;s sole discretion, such discretion to be
      reasonably exercised. None of the parties shall not do or allow to be done any act or thing
      that will in any way impair the rights of the other party&rsquo;s brand.
    </p>
    <p><strong>9. LIMITATION OF LIABILITY</strong></p>
    <p>
      The Client and the End-Users representing such Client individually and collectively hereby
      agree to release, remise and forever discharge the Company and the Company&rsquo;s directors,
      employees, officers, and the Company&rsquo;s affiliates, partners, service providers, vendors,
      and contractors and each of their respective agents, directors, officers, employees, and all
      other related persons or entities from any and all manner of rights, losses, costs, claims,
      complaints, demands, debts, damages, causes of action, proceedings, liabilities, obligations,
      legal fees, costs and disbursements of any nature whatsoever, and for any special, indirect or
      consequential, incidental or exemplary damages, including but not limited to damages for loss
      of profits, goodwill, use data, or other intangible losses (collectively, a
      &ldquo;<strong>Claim</strong>&rdquo;), whether in contract or tort, whether known or unknown,
      which now or hereafter arise from, to the maximum extent allowed by law, that relate to, any
      use of the Product whatsoever. THE CLIENT AND END-USER ACKNOWLEDGES AND AGREES THAT: (A) THE
      PRODUCT IS TO BE USED &ldquo;AS-IS&rdquo;, WITH NO WARRANTIES ON FITNESS FOR THE PURPOSE,
      MERCHANTABILITY OR OF ANY OTHER KIND, WHETHER EXPRESSED OR IMPLIED; AND THE END-USER
      ACKNOWLEDGES, AGREES AND UNDERSTANDS THAT THE END-USER USES THE PRODUCT AT THE
      END-USER&rsquo;S OWN RISK; (B) THE COMPANY IS HEREBY RELEASED FROM ANY AND ALL LIABILITY FOR
      USE OF THE PRODUCT; AND (C) THE CLIENT AND END-USER HAS REVIEWED THE FOLLOWING LIST OF
      POTENTIAL EVENTS SPECIFICALLY DISCLAIMED AS EXAMPLES FOR WHICH THE CLIENT AND END-USER HAS
      SPECIFICALLY RELEASED THE COMPANY FROM ANY AND ALL LIABILITY FOR (AMONG OTHERS):
    </p>
    <ol>
      <li>
        <strong>For non-Product failures</strong>: responsibility for any failure of, or damages to,
        any hardware devices, equipment or networks or internet access, content or data, or
        third-party applications used by the Client and/or End-User in connection with the Product;
      </li>
    </ol>
    <ol>
      <li>
        <strong>For communication failures or Product downtime</strong>: whether due to maintenance
        or other reasons, any error, inaccuracy, omission, interruption, deletion, defect, delay in
        operation or transmission, communications line failure, theft or destruction or unauthorized
        access to, or alteration of, the Product content, End-User User Data, use of Product and/or
        any communications between the End-Users and the Product;
      </li>
    </ol>
    <ol>
      <li>
        <strong>For loss of access as a result of failure to receive payment: </strong>account
        suspension or termination as a result of the End-User&rsquo;s insufficient funds to make
        payment or payment processing issues that are beyond the Company&rsquo;s control (such as
        but not limited to, power outages, interruptions of cellular service, overzealous fraud
        protection rules applied by the User&rsquo;s payment card brand or acquirer bank, or any
        other interface from an outside force);
      </li>
    </ol>
    <ol>
      <li>
        <strong>For content inaccuracy</strong>: any inaccuracy in content and/or recommendations by
        the Company in the Company content and/or the End-User and/or Client User Data, and the
        Client and End-User is responsible for ensuring that that the information entered into the
        Company&rsquo;s system by such End-User is accurate, reliable and complete, and agrees that
        the provision or storage of End-User Data through the Product does not constitute the
        Company&rsquo;s endorsement or warranty as to the accuracy of such content;
      </li>
    </ol>
    <ol>
      <li>
        <strong>For compliance with law</strong>: the compliance of such End-User Data with
        applicable legislation including privacy legislation, and Client and End User agree that the
        provision or storage of End-User Data through the Product does not constitute the
        Company&rsquo;s endorsement or warranty as to compliance with laws;
      </li>
    </ol>
    <ol>
      <li>
        <strong>For online discussions</strong>: responsibility or liability for any user-generated
        commentary including but not limited to user posts, interactions between users, interaction
        between users and Company employees, ratings or reviews of the Client and/or End-User and/or
        an employee of a Client and/or End-User posted to the Product, or any consequences as a
        result of the ratings or reviews of an employee, including but not limited to termination of
        an employee, and ratings and reviews posted to the Product DO NOT reflect the
        Company&rsquo;s views;
      </li>
    </ol>
    <ol>
      <li>
        <strong>For Health Care Provider engagements:&nbsp;</strong>The Health Care Provider is
        solely responsible for the information provided during any counseling session.&nbsp;The
        Company does not support, endorse or certify whatsoever any information provided during any
        Health Care Provider-User engagement&nbsp;The Company shall not be liable whatsoever for any
        damages that may arise resulting from any and all Health Care Provider-User
        engagements.&nbsp;&nbsp;
      </li>
    </ol>
    <ol>
      <li>
        <strong>For monitoring</strong>: liability for monitoring the Product or for unauthorized or
        unlawful content on the Product or use of the Product by any of the Product&rsquo;s Client
        and/or End-User;
      </li>
    </ol>
    <ol>
      <li>
        <strong>For non-infringement</strong>: direct or indirect, express or implied representation
        or warranty as to title and non-infringement of intellectual property in relation to the
        Product.
      </li>
    </ol>
    <ol>
      <li>
        <strong>For processing of content</strong>: liability for any damages that may arise by the
        Client&rsquo;s use and/or direct or third-party processing of Client and/or End-User Data,
        and the Client and/or End-User further agrees and acknowledges that the Company is not
        liable for any damages that may arise if Client and/or End-User Data is misdirected in
        error, subject to, subject to the Company&rsquo;s legal requirements relating to the
        protection of personal information under Applicable Law;
      </li>
    </ol>
    <ol>
      <li>
        <strong>For communications directly or through the Company&rsquo;s Product: </strong
        >liability for any damages that may arise for communications received to the Client and/or
        End-User through the User&rsquo;s access to the Product and/or for the posting of
        information on the Product, Website, blog, account or any affiliated social media,including
        but not limited to, Client and/or End-User Data, pictures, written reviews, personal
        information, psychometric scales and/or comments made from the End-User&rsquo;s personal
        point of view;
      </li>
    </ol>
    <ol>
      <li>
        <strong>For unauthorized activities: </strong>unauthorized activities directed towards the
        Product or its Client and/or End-Users including identity theft, fraud or unauthorized
        access; viruses, denial of service attacks, and any items that are included in the
        definition of Prohibited Use as set out as Schedule &ldquo;C&rdquo; to this Agreement;
      </li>
    </ol>
    <ol>
      <li>
        <strong>For force majeure</strong>: any <em>force majeure </em>event as described in this
        Agreement and/or any matter beyond the Company&rsquo;s reasonable control;
      </li>
    </ol>
    <ol>
      <li>
        <strong>For health content: </strong>any services provided by any health professional,
        therapist or merchant who has provided any content for the Company Product or in any Company
        Social Media Properties;
      </li>
    </ol>
    <ol>
      <li>
        <strong>(o) For actions of health professionals:</strong> the activities or actions, legal
        or otherwise, of any health professional, therapist or merchant who has provided any content
        or advertising for the Company Product or in any Company Social Media Properties;
      </li>
    </ol>
    <ol>
      <li>
        <strong>(p) For complaints of health professionals:</strong> all complaints/disputes
        relating to the quality of health professionals or therapists or any other merchant&rsquo;s
        products/services;&nbsp;
      </li>
    </ol>
    <ol>
      <li>
        <strong>(q) For claims or disputes made by health professionals:</strong>
        inquiries/claims/disputes regarding payment processing, payments or refunds made via health
        professionals or therapists or merchant accounts through the Company Product; and/or
      </li>
    </ol>
    <ol>
      <li>
        <strong>(r) For reliance on content:</strong> your reliance on any content contained in the
        Company Product or in any Company Social Media Properties.
      </li>
    </ol>
    <p>
      In the event that there is a finding of liability pursuant to the dispute resolution
      provisions of this Agreement that is contrary to the foregoing, the Client and End-Users agree
      that such damages shall be limited in the aggregate for all Claims related to all of the
      End-Users registered on behalf of a Client and the Client itself (individually and
      collectively, the Claimants) to the fees or charges which the Claimants have paid for the
      Product, if any, in the previous invoice for the services giving rise to the claim, whether or
      not any or all of the Claimants have been advised of the possibility of such damages or such
      Claim was reasonably foreseeable and notwithstanding the sufficiency or insufficiency of any
      remedy provided for herein.
    </p>
    <p><strong>10. INDEMNIFICATION</strong></p>
    <p>
      To the extent permitted by applicable laws, the Client and/or End-User agree that the Client
      and/or End-User will defend, indemnify and hold harmless the Company and the Company&rsquo;s
      officers, directors, shareholders, employees, agents and representatives, from and against any
      and all damages, judgments, liability, costs and expenses (including without limitation any
      reasonable legal fees), in whole or in part arising out of or attributable to: (a) generally,
      for the Client and/or End-User&rsquo;s breach of this Agreement; the End-User&rsquo;s access
      to and/or use of the Product; and any loss of, or damage to, any property, or injury to, or
      death of, any person (including the Client and/or End-User) caused by the Client and/or
      End-User&rsquo;s access to and/or use of the Product; and (b) specifically, for the
      End-User&rsquo;s breach of the intellectual property rights of any third party to this
      Agreement; and/or (c) for any Prohibited Use.
    </p>
    <p>
      The Client and/or End-User agrees that the Client and/or End-User will be solely responsible
      for all activities that occur under the End-User&rsquo;s account, whether the End-User is
      aware of them or not. The Client and/or End-User agrees to hold the Company harmless and
      release the Company from any loss or liability whatsoever that the Client and/or End-User may
      incur as a result of someone other than the Client and/or End-User using the Client and/or
      End-User&rsquo;s password or account, either with or without the Client and/or
      End-User&rsquo;s knowledge. The Client and/or End-User agrees to indemnify the Company for any
      damages, third party claims or liabilities whatsoever that the Company may incur as a result
      of activities that occur on or through the Client and/or End-User&rsquo;s account, whether or
      not the Client and/or End-User were directly or personally responsible.
    </p>
    <p><strong>11. GOVERNING LAW AND FORUM OF DISPUTES</strong></p>
    <p>
      The Client and/or End-User agree that the laws of the province of Ontario and the federal laws
      of Canada as applicable therein, without regard to the principles of conflict of laws
      (&ldquo;<strong>Applicable Law</strong>&rdquo;), will govern this Agreement and any dispute of
      any sort that may arise between the Client and/or End-User and us. With respect to any
      disputes or claims, the Client and/or End-User agree not to commence or prosecute any action
      in connection therewith other than in the province of Ontario, and the Client and/or End-User
      hereby consent to and waive all defenses of lack of personal jurisdiction and
      <em>forum non conveniens</em> with respect to venue and jurisdiction in the provincial courts
      of Ontario. The Client and/or End-User agree to pay reasonable attorneys' fees and court costs
      incurred by the Company to collect any unpaid amounts owed by the Client and/or End-User.
    </p>
    <p><strong>12. EXPENSES</strong></p>
    <p>
      Other than in the event of a dispute, in which case the apportionment of expenses shall be
      determined pursuant to the dispute resolution rules, each Party shall be responsible for its
      own legal fees and other expenses incurred in connection with the negotiation of these terms
      (if any) and the performance of any of such Party&rsquo;s obligations hereunder.
    </p>
    <p><strong>13. FORCE MAJEURE</strong></p>
    <p>
      The Client and/or End-User agree that the Company are not liable for a delay or failure in
      performance of the Product or the provisions of this Agreement caused by reason of any
      occurrence of unforeseen events beyond the Company&rsquo;s reasonable control, including but
      not limited to, acts of God, natural disasters, power failures, server failures, third party
      service provider failures or service interruptions, embargo, labour disputes, lockouts and
      strikes, riots, war, floods, insurrections, legislative changes, and governmental actions.
    </p>
    <p><strong>14. SEVERABILITY</strong></p>
    <p>
      If any portion of this Agreement is deemed unlawful, void or unenforceable by any arbitrator
      or court of competent jurisdiction, this Agreement as a whole shall not be deemed unlawful,
      void or unenforceable, but only that portion of this Agreement that is unlawful, void or
      unenforceable shall be stricken from this Agreement.
    </p>
    <p><strong>15. HEADINGS</strong></p>
    <p>
      The insertions of headings are for convenient reference only and are not to affect the
      interpretation of this Agreement.
    </p>
    <p><strong>16. ASSIGNMENT OF AGREEMENT</strong></p>
    <p>
      The Client and/or End-User may not, without the Company&rsquo;s prior written consent, assign
      the Agreement, in whole or in part, either voluntarily or by operation of law, and any attempt
      to do so will be a material default of the Agreement and will be void. The Company may assign
      this Agreement to a third party at any time in the Company&rsquo;s sole discretion. The
      Agreement will be binding upon and will inure to the benefit of the respective parties hereto,
      their respective successors in interest, legal representatives, heirs and assigns.
    </p>
    <p><strong>17. WAIVER</strong></p>
    <p>
      The Client and/or End-User agrees that if the Company does not exercise or enforce any legal
      right or remedy which is contained in this Agreement or which the Company has the benefit of
      under any Applicable Law, this will not be taken to be a formal waiver of the Company&rsquo;s
      rights and that those rights or remedies will still be available to us. Waivers must be in
      written form and signed by an authorized representative of the Company.
    </p>
    <p><strong>18. SURVIVAL OF AGREEMENT</strong></p>
    <p>
      All covenants, agreements, representations and warranties made in this Agreement shall survive
      the End-User&rsquo;s acceptance of this Agreement and the termination of this Agreement.
    </p>
    <p><strong>19. ENTIRE AGREEMENT</strong></p>
    <p>
      The Product License Agreement and its schedules will constitute the entire agreement between
      the Company and the Client and/or End-User with respect to the subject matter hereof, and all
      prior oral or written agreements, representations or statements with respect to such subject
      matter are superseded hereby. In the event of a conflict between this Agreement and the
      Privacy Policy (Found at: "sentryhealth.ca/privacy-policy "), the terms and conditions found
      herein shall prevail.
    </p>
    <p><strong>20. CONTACT</strong></p>
    <p>
      By providing the Company with the End-User&rsquo;s e-mail address, whether provided directly
      by the End-User or by the Client on behalf of the End-User, the End-User agrees to receive all
      required notices electronically, to that e-mail address or by mobile notifications via the
      Product. It is the End-User&rsquo;s responsibility to update or change that address, as
      appropriate.
    </p>
    <p>
      If the Client and/or End-User have any questions or comments regarding this Agreement, please
      contact the Company&rsquo;s head officeby email at "<strong
        ><em>{{ CONTACT_EMAIL }}</em></strong
      >
      ".
    </p>
    <p style="text-align: center">
      <em>[The remainder of this page is intentionally left blank]</em>
    </p>
    <p style="text-align: center"><strong>Schedule &ldquo;B&rdquo;</strong></p>
    <p style="text-align: center"><strong>Product Scope and Features</strong></p>
    <p>
      The Product, is limited to the following features and License Restrictions contained in the
      version available during the Initial Term and subsequent Renewal Terms, and includes:
    </p>
    <ol>
      <li>Patient scheduling;</li>
    </ol>
    <ol>
      <li>Automated data intake/capture;</li>
    </ol>
    <ol>
      <li>Video/phone solutions;</li>
    </ol>
    <ol>
      <li>Clinical workflow tools; and</li>
    </ol>
    <ol>
      <li>the ability to submit feedback to improve Product's offerings.&nbsp;</li>
    </ol>
    <p>
      The list of features may change from time to time. Any new features added to the product shall
      be governed by these Terms of Use unless otherwise specified by the Company.
    </p>
    <p style="text-align: center">
      <em>[The remainder of this page is intentionally left blank]</em>
    </p>
    <p style="text-align: center"><strong>Schedule &ldquo;C&rdquo;</strong></p>
    <p style="text-align: center"><strong>Prohibited Uses</strong></p>
    <p>
      The Client and/or End-Users may use the Product only for lawful purposes, and may not use the
      Product in any manner that:
    </p>
    <ol>
      <li>
        breaches any applicable local, national or international law or regulation;
      </li>
    </ol>
    <ol>
      <li>may in any way be considered harassment to another person or entity;</li>
    </ol>
    <ol>
      <li>
        may in any way be unlawful or fraudulent, or has any unlawful or fraudulent purpose or
        effect;
      </li>
    </ol>
    <ol>
      <li>
        may in any way damage, disable, overburden, and/or impair the Product server, or any network
        connected to the Product server, and/or interfere with any other party&rsquo;s use or
        enjoyment of the Product;
      </li>
    </ol>
    <ol>
      <li>
        is in any way abusive, defamatory, misleading, fraudulent, pornographic or otherwise
        explicit in nature or written in bad faith;
      </li>
    </ol>
    <ol>
      <li>harms or attempts to harm minors in any way;</li>
    </ol>
    <ol>
      <li>
        will abuse either verbally, physically, written or other abuse (including threats of abuse
        or retribution) of any Product customers, employees, contractors, subcontractors, members,
        or officers;
      </li>
    </ol>
    <ol>
      <li>
        will cause sensitive personal information records of any individual (including, but not
        limited to, credit card information, personal health records, military information, and
        passport information) to be attained or used illegally or in an unauthorized manner;
      </li>
    </ol>
    <ol>
      <li>
        will decipher, decompile, disassemble, reconstruct, translate, reverse engineer, or discover
        any of the intellectual property or ideas, algorithms, file formats, programming, or
        interoperability interfaces underlying the Product;
      </li>
    </ol>
    <ol>
      <li>
        will modify, rent, lease, loan, sell, distribute or create any derivative products or
        services (or parts of services products or services) based on the Product Content that the
        End-User does not own or to which the End-User has rights, or to create derivative works
        based on the Product;
      </li>
    </ol>
    <ol>
      <li>
        will infringe upon the Company&rsquo;s intellectual property or adapt, reproduce, publish or
        distribute copies of any information or material found on the Product in any form (including
        by e-mail or other electronic means), without the Company&rsquo;s prior written consent;
      </li>
    </ol>
    <ol>
      <li>
        is for the benefit of or permit a third party, who is not a registered End-User, to use the
        Product;
      </li>
    </ol>
    <ol>
      <li>
        will attempt to gain unauthorized access to, or disrupt the integrity or performance of the
        Product or the data contained therein;
      </li>
    </ol>
    <ol>
      <li>
        will use the Product to upload, post, link to, email, transmit, or otherwise make available
        any material that contains software viruses or any other computer code, files or programs
        designed to interrupt, destroy, or limit the functionality of any computer software or
        hardware or any telecommunications equipment
      </li>
    </ol>
    <ol>
      <li>
        will the post or distribute any computer program that damages, detrimentally interferes
        with, surreptitiously intercepts, or expropriates any system, data, or personal information
      </li>
    </ol>
    <ol>
      <li>will disrupt the functioning of the Product, in any manner; and/or</li>
    </ol>
    <ol>
      <li>
        in the event that the Product is being used on a mobile device, shall not be used in a way
        that distracts the End-User and/or prevents the End-User from obeying traffic or safety
        laws,
      </li>
    </ol>
    <p>
      and any of the foregoing (&ldquo;<strong>Prohibited Uses</strong>&rdquo;) may, once
      investigated, result in immediate account termination for an End-User account and agreement
      and/or termination of an agreement with the Client who has registered the account for such
      End-User.
    </p>
  </div>
</template>
<style scoped>
.tos {
  color: black;
}
</style>

<script>
/* eslint-disable max-len */
export default {
  name: 'tos',
  data() {
    return {
      CONTACT_EMAIL: 'hello@sentryhealth.ca',
      EFFECTIVE_DATE: 'August 3rd, 2021',
      LAST_MODIFIED_DATE: 'August 3rd, 2021',
    };
  },
};
</script>
