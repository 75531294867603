<template>
  <v-snackbar
    :color="notification.color"
    :timeout="notification.timeout"
    bottom
    left
    v-model="notification.showing"
    id="NotificationSnack"
  >
    <div>{{ notification.text }}</div>
    <div v-if="notification.linkUrl">
      <a :href="notification.linkUrl" target="_blank" class="link-text font-weight-bold">{{
        notification.linkText
      }}</a>
    </div>

    <template v-slot:action="{ attrs }">
      <v-btn v-bind="attrs" icon color="transparent" @click="notification.showing = false">
        <v-icon color="white"> mdi-close </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>
<style scoped>
.link-text {
  color: white;
}
</style>
<script>
import { mapState } from 'vuex';

export default {
  name: 'NotificationSnack',
  data: () => ({}),
  computed: {
    ...mapState(['notification']),
  },
};
</script>
