<template>
  <div class="text-center d-flex flex-column align-center justify-center">
    <div class="mt-3">
      <h2>
        {{ $t('password.enterPassword') }}
      </h2>
      <div class="enter-password-container d-flex justify-center mt-8">
        <div class="enter-password">
          <v-text-field
            outlined
            width="100"
            v-mask="code"
            v-model="password"
            :error-messages="errors"
            class="password-input"
            @keydown="onKeydown"
            :loading="loading"
          >
          </v-text-field>
        </div>
      </div>
      <div class="mt-3">
        <v-btn color="primary" :disabled="isDisabled" @click="onSubmit">{{
          $t('login.submit')
        }}</v-btn>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.enter-password {
  width: 200px;
  .v-text-field__slot {
    input {
      text-align: center;
      font-size: 2em !important;
    }
  }
}
.enter-password-container {
  width: 100%;
}
.password-input {
  input {
    text-transform: uppercase;
  }
}
</style>
<script>
import to from 'await-to-js';
import { code } from '../constants/masks';
import GET_LINK from '../graphql/Query/ReadOTNLinkByAlias.gql';
import { COMMON_KEY } from '../constants/env';

export default {
  name: 'Password',
  data() {
    return {
      code,
      loading: false,
      password: null,
      errors: [],
    };
  },
  computed: {
    isDisabled() {
      return this.password?.length !== 7;
    },
    isExpress() {
      return !!this.$route.query.express;
    },
  },
  methods: {
    onKeydown(e) {
      if (e.code !== 'Enter') {
        return;
      }
      if (this.isDisabled) {
        return;
      }
      this.onSubmit();
    },
    async onSubmit() {
      this.loading = true;
      this.errors = [];

      // format for backend: xxxxxx
      let accessCode = this.password.split('');
      accessCode.splice(3, 1);
      accessCode = accessCode.join('').toLowerCase();

      const [errors, query] = await to(
        this.$apollo.query({
          query: GET_LINK,
          variables: {
            accessCode,
            commonKey: COMMON_KEY,
          },
          fetchPolicy: 'no-cache',
        })
      );

      if (errors) {
        this.errors.push(this.$t('password.error'));
      } else {
        const facilitationToken = query.data.readOTNLinkByAlias;
        this.$router.push({
          name: 'Gateway',
          query: { express: this.isExpress, t: facilitationToken },
        });
      }
      this.loading = false;
    },
  },
  async created() {
    await this.$store.dispatch('resetSession');
  },
  mounted() {
    this.$store.commit('set', {
      meetContainer: { height: '450px', width: '650px' },
    });
  },
};
</script>
