<template>
  <div class="d-flex justify-space-between flex-column text-center">
    <div>
      <div v-if="roomStatus === ROOM_STATUS.READY">
        <audio autoplay loop>
          <source src="../assets/doctor_ready.wav" />
        </audio>
        <v-icon size="100" color="green">mdi-checkbox-marked-circle</v-icon>
      </div>
      <div class="mb-6 waiting-room-text">
        <h2>
          <span class="font-weight-bold">{{ title }}</span> <br />
        </h2>
      </div>
      <div>
        <div class="mb-10 waiting-room-text" v-if="subtitle">
          {{ subtitle }}
        </div>
        <div class="back pa-4 d-flex justify-center align-center">
          <div>
            <v-btn @click="onClick" :color="buttonColor"
              ><v-icon class="pr-2" v-if="roomStatus === ROOM_STATUS.PENDING">mdi-cog</v-icon
              >{{ buttonText }}</v-btn
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.waiting-room-text {
  max-width: 400px;
}
</style>
<script>
import to from 'await-to-js';
import GET_ROOM_STATUS from '../graphql/Query/GetRoomStatus.gql';
import { ROOM_STATUS } from '../constants/meet';

export default {
  name: 'WaitingRoom',
  data() {
    return {
      ROOM_STATUS,
    };
  },
  computed: {
    isMeetingStarted() {
      return this.$store.state.roomStatus === ROOM_STATUS.READY;
    },
    appointmentId() {
      return this.$store.state.appointmentId;
    },
    buttonColor() {
      switch (this.roomStatus) {
        case ROOM_STATUS.READY: {
          return 'primary';
        }
        case ROOM_STATUS.COMPLETED: {
          return 'primary';
        }
        default: {
          return '';
        }
      }
    },
    buttonText() {
      switch (this.roomStatus) {
        case ROOM_STATUS.PENDING: {
          return this.$t('header.back');
        }
        case ROOM_STATUS.READY: {
          return this.$t('actions.joinRoom');
        }
        case ROOM_STATUS.COMPLETED: {
          return this.$t('instructions.joinAnotherMeeting');
        }
        default: {
          return this.$t('header.back');
        }
      }
    },
    eventId() {
      return this.$store.state.eventId;
    },
    providerId() {
      return this.$store.state.providerId;
    },
    roomStatus() {
      return this.$store.state.roomStatus;
    },
    subtitle() {
      switch (this.roomStatus) {
        case ROOM_STATUS.PENDING: {
          return this.$t('instructions.standBySubtitle');
        }
        case ROOM_STATUS.READY: {
          return null;
        }
        case ROOM_STATUS.COMPLETED: {
          return null;
        }
        default: {
          return this.$t('instructions.standBySubtitle');
        }
      }
    },
    title() {
      switch (this.roomStatus) {
        case ROOM_STATUS.PENDING: {
          return this.$t('instructions.standByTitle');
        }
        case ROOM_STATUS.READY: {
          return this.$t('instructions.readyTitle');
        }
        case ROOM_STATUS.COMPLETED: {
          return this.$t('instructions.completedTitle');
        }
        default: {
          return this.$t('instructions.standByTitle');
        }
      }
    },
  },
  methods: {
    back() {
      this.$router.push({ name: 'Options' });
    },
    endFlow() {
      this.$router.push({ name: 'Password' });
    },
    async getEvents() {
      const [errors, query] = await to(
        this.$apollo.query({
          query: GET_ROOM_STATUS,
          variables: {
            providerId: this.$store.state.providerId,
            eventIds: [this.$store.state.eventId],
          },
          fetchPolicy: 'no-cache',
        })
      );
      if (!errors) {
        const { roomStatus } = query.data.getEvents[0];
        this.$store.commit('set', { roomStatus });
      }
    },
    onClick() {
      switch (this.roomStatus) {
        case ROOM_STATUS.PENDING: {
          this.back();
          break;
        }
        case ROOM_STATUS.READY: {
          this.startMeeting();
          break;
        }
        case ROOM_STATUS.COMPLETED: {
          this.endFlow();
          break;
        }
        default: {
          this.back();
        }
      }
    },
    startMeeting() {
      this.$router.push({ name: 'Call' });
    },
  },
  async mounted() {
    this.getEvents();
    this.$store.commit('set', {
      meetContainer: { height: '450px', width: '650px' },
    });
  },
};
</script>
