<template>
  <div class="d-flex expired-container justify-space-between flex-column text-center">
    <div class="mb-8 ">
      <h2>{{ $t('errors.linkExpired') }}</h2>
    </div>
    <div>
      <div>
        {{ $t('instructions.bookAppointment') }}
      </div>
      <div>
        {{ $t('sentry.phoneSupport') }}
      </div>
      <div class="mt-8">
        <v-btn color="primary" @click="leave">{{ $t('instructions.joinAnotherMeeting') }}</v-btn>
      </div>
    </div>
  </div>
</template>
<style scoped>
.expired-container {
  height: 125px;
}
</style>
<script>
export default {
  name: 'Expired',
  data() {
    return {};
  },
  mounted() {
    this.$store.commit('set', {
      meetContainer: { height: '450px', width: '650px' },
    });
  },
  methods: {
    async leave() {
      this.$router.push({ name: 'Password' });
    },
  },
};
</script>
