<template>
  <v-container class="d-flex flex-column align-center justify-center meet-wrapper pt-12 py-lg-0">
    <v-sheet
      class="meet-container d-flex flex-column align-center"
      :class="hideLogo ? 'pa-0' : 'px-6 px-lg-8 py-12'"
      outlined
      rounded
      :style="{ minHeight: height, width }"
    >
      <div v-if="!hideLogo" class="logo-image-container d-flex justify-center mb-12">
        <div class="logo-image"><img :alt="$t('company')" src="../assets/logo.png" /></div>
      </div>
      <slot></slot>
    </v-sheet>
  </v-container>
</template>
<style scoped>
.logo-image-container {
  width: 100%;
}
.logo-image {
  position: relative;
  height: 65px;
  cursor: pointer;
}
.logo-image > img {
  height: 100%;
}
.meet-wrapper {
  height: 100%;
}

.meet-container {
  transition: all 1s;
  position: relative;
}

@media all and (max-width: 768px) {
  .meet-container {
    max-width: 100% !important;
  }
}
</style>
<script>
export default {
  name: 'MeetContainer',

  data() {
    return {
      isMeetingStarted: false,
    };
  },
  computed: {
    height() {
      if (this.$route.name === 'Call' && this.$vuetify.breakpoint.mdAndDown)
        return '100%!important';
      if (this.$vuetify.breakpoint.smAndDown) {
        return '';
      }
      return this.$store.state.meetContainer.height;
    },
    hideLogo() {
      return this.$route.meta.hideLogo;
    },
    width() {
      if (this.$route.name === 'Call' && this.$vuetify.breakpoint.mdAndDown)
        return '100%!important';
      if (this.$vuetify.breakpoint.smAndDown) {
        return '';
      }
      return this.$store.state.meetContainer.width;
    },
  },
};
</script>
