<template>
  <v-app>
    <notification-snack></notification-snack>
    <v-main>
      <server-status></server-status>
      <language-select @change="setLang" />
      <meet-container>
        <loading v-if="isLoading"></loading>
        <router-view></router-view>
      </meet-container>
      <help-bubble />
    </v-main>
  </v-app>
</template>

<style>
body,
.v-application {
  font-family: 'Montserrat', sans-serif !important;
}
</style>

<script>
import HelpBubble from './components/HelpBubble.vue';
import LanguageSelect from './components/LanguageSelect.vue';
import Loading from './components/Loading.vue';
import MeetContainer from './components/MeetContainer.vue';
import NotificationSnack from './components/NotificationSnack.vue';
import ServerStatus from './components/ServerStatus.vue';
import { MEETING_INFO, LANG } from './constants/storage';
import i18n from './plugins/i18n';

export default {
  name: 'App',
  components: {
    HelpBubble,
    LanguageSelect,
    Loading,
    NotificationSnack,
    MeetContainer,
    ServerStatus,
  },
  data: () => ({}),
  computed: {
    isLoading() {
      return this.$store.state.loading;
    },
    lang() {
      return this.$store.state.lang;
    },
    meetingInfo() {
      const { providerId, appointmentId } = this.$store.state;
      return !!providerId && !!appointmentId;
    },
  },
  created() {
    const lang = localStorage.getItem(LANG);
    this.setLang(lang);
  },
  methods: {
    setLang(val) {
      localStorage.setItem(LANG, val);
      this.$store.commit('set', { lang: val || 'en' });
      i18n.locale = val;
      this.$vuetify.lang.current = val;
    },
  },
  watch: {
    meetingInfo: {
      immediate: true,
      handler(val) {
        if (!val) return;
        const { providerId, appointmentId } = this.$store.state;
        localStorage.setItem(MEETING_INFO, `${providerId}-${appointmentId}`);
      },
    },
  },
};
</script>
