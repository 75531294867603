<template>
  <div class="help-bubble__wrap" :style="position" :class="shouldMobilePosition ? 'ma-1' : 'ma-6'">
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          fab
          :small="shouldMobilePosition"
          class="help__bubble"
          color="success"
          @click="goToFaq"
          ><v-icon>mdi-help</v-icon></v-btn
        >
      </template>
      <span>{{ $t('helpBubble.tooltip') }}</span>
    </v-tooltip>
  </div>
</template>
<style lang="scss">
.help-bubble__wrap {
  position: absolute;
  right: 0px;
}
</style>
<script>
export default {
  name: 'HelpBubble',
  data() {
    return {};
  },
  computed: {
    route() {
      return this.$route.name;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    shouldMobilePosition() {
      return this.isMobile && this.route === 'Call';
    },
    position() {
      if (this.shouldMobilePosition) return 'top: 0';
      return 'bottom: 0';
    },
  },
  methods: {
    goToFaq() {
      window.open('https://www.sentryhealth.ca/meet-faq', '_blank');
    },
  },
};
</script>
