/* eslint-disable import/no-cycle */
import createLogger from 'vuex/dist/logger';
import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';
import { apolloClient } from '../vue-apollo';

import { NODE_ENV } from '../constants/env';
import { AUTH_TOKEN, VUEX_STORE, EXCLUDE_KEYS, MEETING_INFO } from '../constants/storage';
import SET_PATIENT_READY from '../graphql/Mutation/SetPatientReady.gql';

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: VUEX_STORE,
  storage: window.localStorage,
  reducer: (state) => {
    const copy = { ...state };

    EXCLUDE_KEYS.forEach((k) => {
      delete copy[k];
    });

    return copy;
  },
});

const defaultNotification = {
  color: '',
  showing: false,
  text: '',
  timeout: -1,
  linkText: '',
  linkUrl: null,
};

const defaultState = () => {
  return {
    appointmentId: null,
    authToken: null,
    facilitationToken: null,
    roomStatus: null,
    eventId: null,
    express: false,
    loading: false,
    meetContainer: {
      height: '450px',
      width: '650px',
    },
    notification: { ...defaultNotification },
    participantLink: null,
    patientReady: false,
    providerId: null,
    status: null,
  };
};

export default new Vuex.Store({
  state: {
    ...defaultState(),
    lang: 'en',
  },
  mutations: {
    clearLocalStorage() {
      localStorage.removeItem(AUTH_TOKEN);
      localStorage.removeItem(MEETING_INFO);
    },
    resetState(state) {
      Object.assign(state, defaultState());
    },
    set(state, updates) {
      Object.assign(state, updates);
    },
  },
  actions: {
    clearNotification({ commit }) {
      commit('set', {
        notification: {
          ...defaultNotification,
          showing: false,
        },
      });
    },
    setNotification({ commit }, notification) {
      commit('set', {
        notification: {
          ...defaultNotification,
          ...notification,
          showing: true,
        },
      });
    },
    async setPatientReady({ state }, isReady) {
      const { providerId, appointmentId } = state;
      if (!providerId || !appointmentId) return;
      await apolloClient.mutate({
        mutation: SET_PATIENT_READY,
        variables: {
          providerId,
          appointmentId,
          isReady,
        },
        client: 'gateway',
      });
      state.patientReady = isReady;
    },
    resetSession({ commit }) {
      commit('resetState');
      commit('clearLocalStorage');
    },
  },
  plugins: [vuexLocalStorage.plugin, ...(NODE_ENV === 'development' ? [createLogger()] : [])],
});
