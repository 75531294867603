<template>
  <div class="d-flex expired-container justify-space-between flex-column text-center">
    <div class="mb-10 ">
      <h2>{{ $t('errors.accessDenied') }}</h2>
    </div>
    <div>
      <div class="mb-10">
        {{ $t('instructions.accessAppointment') }}
      </div>
      <div>
        <v-btn color="primary" @click="enterManualCode">{{ $t('actions.enterCode') }}</v-btn>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NoAccess',
  data() {
    return {};
  },
  methods: {
    enterManualCode() {
      this.$router.push({ name: 'Password' });
    },
  },
  mounted() {
    this.$store.commit('set', {
      meetContainer: { height: '450px', width: '650px' },
    });
  },
};
</script>
